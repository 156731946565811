import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { pick, get } from 'lodash';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS';
export const REQUEST_LOGIN_FAILURED = 'REQUEST_LOGIN_FAILURED';

export const REQUEST_REGISTER = 'REQUEST_REGISTER';
export const REQUEST_REGISTER_SUCCESS = 'REQUEST_REGISTER_SUCCESS';
export const REQUEST_REGISTER_FAILURED = 'REQUEST_REGISTER_FAILURED';

export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const REQUEST_FORGOT_PASSWORD_SUCCESS = 'REQUEST_FORGOT_PASSWORD_SUCCESS';
export const REQUEST_FORGOT_PASSWORD_FAILURED = 'REQUEST_FORGOT_PASSWORD_FAILURED';

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';
export const REQUEST_RESET_PASSWORD_FAILURED = 'REQUEST_RESET_PASSWORD_FAILURED';
export const SAVE_RESET_PASSWORD_TOKEN = 'SAVE_RESET_PASSWORD_TOKEN';

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const REQUEST_LOGOUT_SUCCESS = 'REQUEST_LOGOUT_SUCCESS';
export const REQUEST_LOGOUT_FAILURED = 'REQUEST_LOGOUT_FAILURED';

export const REQUEST_LOGIN_TOKEN = 'REQUEST_LOGIN_TOKEN';
export const REQUEST_LOGIN_TOKEN_SUCCESSED = 'REQUEST_LOGIN_TOKEN_SUCCESSED';
export const REQUEST_LOGIN_TOKEN_FAILURED = 'REQUEST_LOGIN_TOKEN_FAILURED';

export const FETCH_USER_SAGA = 'FETCH_USER_SAGA';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURED = 'FETCH_USER_FAILURED';

export const UPDATE_USER_CONFIG_BY_COLUMN           = 'UPDATE_USER_CONFIG_BY_COLUMN';
export const UPDATE_USER_CONFIG_BY_COLUMN_SUCCESS   = 'UPDATE_USER_CONFIG_BY_COLUMN_SUCCESS';
export const UPDATE_USER_CONFIG_BY_COLUMN_FAILURED  = 'UPDATE_USER_CONFIG_BY_COLUMN_FAILURED';

export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const LOGOUT_SAGA = 'LOGOUT_SAGA';

export const REQUEST_VALIDATE_BEFORE_REGISTER = 'REQUEST_VALIDATE_BEFORE_REGISTER';
export const REQUEST_VALIDATE_BEFORE_REGISTER_SUCCESSED = 'REQUEST_VALIDATE_BEFORE_REGISTER_SUCCESSED';
export const REQUEST_VALIDATE_BEFORE_REGISTER_FAILURED = 'REQUEST_VALIDATE_BEFORE_REGISTER_FAILURED';

const defaultState = {
  isAuthFinshed: false,
  isAuthRequesting: false,
  isAuthFailured: false,
  isAuthSuccessed: false,
  token: "",
  error: {},
  user: {},
  beforeRegisterValidResult: false,
  fetchingApiRegister: false,
  userConfig: {},
  updatingUserConfig: true,
  failedUpdatingUserConfig: false,
  resetPasswordToken: null
};

const reducer = createReducer(defaultState, {
  [REQUEST_LOGIN]: state => ({
    ...state,
    isAuthRequesting: true
  }),
  [REQUEST_LOGIN_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isAuthFinshed: true,
      isAuthFailured: false,
      isAuthSuccessed: true,
      isAuthRequesting: false,
    }
  },
  [REQUEST_LOGIN_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      isAuthFinshed: true,
      isAuthFailured: true,
      isAuthRequesting: false,
      isAuthSuccessed: false,
    }
  },
  [REQUEST_LOGIN_TOKEN]: state => ({
    ...state,
    isAuthRequesting: true,
    isAuthFinshed: false,
    isAuthFailured: false,
    isAuthSuccessed: false,
  }),
  [REQUEST_LOGIN_TOKEN_SUCCESSED]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isAuthFinshed: true,
      isAuthFailured: false,
      isAuthSuccessed: true,
      isAuthRequesting: false,
    }
  },
  [REQUEST_LOGIN_TOKEN_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      isAuthFinshed: true,
      isAuthFailured: true,
      isAuthRequesting: false,
      isAuthSuccessed: false,
    }
  },
  [LOGOUT_ACTION]: (state) => {
    return {
      ...state,
      isAuthFailured: false,
      isAuthSuccessed: false,
      isAuthRequesting: false,
    }
  },
  [FETCH_USER_SUCCESS]: (state, action) => {
    const user = action.payload;
    return {
      ...state,
      user,
      isAuthFinshed: true,
      isAuthFailured: false,
      isAuthSuccessed: true,
      isAuthRequesting: false,
    }
  },
  [FETCH_USER_FAILURED]: (state, action) => { 
    return {
      ...state,
      error: action.payload,
      isAuthFinshed: true,
      isAuthFailured: true,
      isAuthRequesting: false,
      isAuthSuccessed: false,
    }
  },

  [UPDATE_USER_CONFIG_BY_COLUMN]: (state, action) => ({
    ...state,
    updatingUserConfig: true,
    failedUpdatingUserConfig: false,
  }),
  [UPDATE_USER_CONFIG_BY_COLUMN_SUCCESS]: (state, action) => ({
    ...state,
    userConfig: action.payload.data,
    updatingUserConfig: false,
    failedUpdatingUserConfig: false,
  }),
  [UPDATE_USER_CONFIG_BY_COLUMN_FAILURED]: (state, action) => ({
    ...state,
    updatingUserConfig: false,
    failedUpdatingUserConfig: true,
  }),
  [SAVE_RESET_PASSWORD_TOKEN]: (state, action) => ({
    ...state,
    resetPasswordToken: action.payload
  }),
  [REQUEST_VALIDATE_BEFORE_REGISTER]: (state, action) => ({
    ...state,
    fetchingApiRegister: true
  }),
  [REQUEST_VALIDATE_BEFORE_REGISTER_SUCCESSED]: (state, action) => ({
    ...state,
    beforeRegisterValidResult: get(action, 'payload.data.status'),
    fetchingApiRegister: false
  }),
  [REQUEST_VALIDATE_BEFORE_REGISTER_FAILURED]: (state, action) => ({
    ...state,
    fetchingApiRegister: false
  })
});

export default reducer;
export const namespace = 'auth';

export const logoutFake = (payload) => ({
  type: REQUEST_LOGOUT_SUCCESS,
  payload
});

export const saveResetPasswordToken = (payload) => ({
  type: SAVE_RESET_PASSWORD_TOKEN,
  payload
});

export const logout =  () => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/admins/logout',
    actionTypes: [REQUEST_LOGOUT, REQUEST_LOGOUT_SUCCESS, REQUEST_LOGOUT_FAILURED],
    variables: {},
    dispatch,
    getState
  });
};

export const login =  (username, password) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/seller/auth/login',
    actionTypes: [REQUEST_LOGIN, REQUEST_LOGIN_SUCCESS, REQUEST_LOGIN_FAILURED],
    variables: {
      username,
      password
    },
    dispatch,
    getState
  });
};

export const fetchUserInfor = () => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/seller/auth/me',
    actionTypes: [FETCH_USER, FETCH_USER_SUCCESS, FETCH_USER_FAILURED],
    variables: {},
    dispatch,
    getState
  });
};

export const register = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/seller/auth/register',
    actionTypes: [REQUEST_REGISTER, REQUEST_REGISTER_SUCCESS, REQUEST_REGISTER_FAILURED],
    variables: data,
    dispatch,
    getState
  });
};

export const forgotPassword = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/seller/auth/forgot-password',
    actionTypes: [REQUEST_FORGOT_PASSWORD, REQUEST_FORGOT_PASSWORD_SUCCESS, REQUEST_FORGOT_PASSWORD_FAILURED],
    variables: data,
    dispatch,
    getState
  });
};

export const resetPassword = (token, data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: `/seller/auth/change-password/${token}`,
    actionTypes: [REQUEST_RESET_PASSWORD, REQUEST_RESET_PASSWORD_SUCCESS, REQUEST_RESET_PASSWORD_FAILURED],
    variables: data,
    dispatch,
    getState
  });
};

export const validateBeforeRegister = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/seller/auth/before-register',
    actionTypes: [REQUEST_VALIDATE_BEFORE_REGISTER, REQUEST_VALIDATE_BEFORE_REGISTER_SUCCESSED, REQUEST_VALIDATE_BEFORE_REGISTER_FAILURED],
    variables: pick(data, ['username', 'email', 'phone_number']),
    dispatch,
    getState
  });
};

export const selectUser = state => state[namespace].user;
