import { createReducer } from 'redux-create-reducer';
// import callAPI from '../../../utils/callAPI';
// import { ROLE_CS } from "../../../utils/constants";
import { get } from 'lodash';
import callAPI from "../../../utils/callAPI";

export const REQUEST_CHECKOUT = 'REQUEST_CHECKOUT';
export const REQUEST_CHECKOUT_SUCCESS = 'REQUEST_CHECKOUT_SUCCESS';
export const REQUEST_CHECKOUT_FAILURE = 'REQUEST_CHECKOUT_FAILURE';

export const ACTION_CHANGE_CHECKOUT_FILTER = 'ACTION_CHANGE_CHECKOUT_FILTER';

export const REQUEST_CHECK_VOUCHER = 'REQUEST_CHECK_VOUCHER';
export const REQUEST_CHECK_VOUCHER_SUCCESS = 'REQUEST_CHECK_VOUCHER_SUCCESS';
export const REQUEST_CHECK_VOUCHER_FAILURE = 'REQUEST_CHECK_VOUCHER_FAILURE';

export const REQUEST_TRANSPORT = 'REQUEST_TRANSPORT';
export const REQUEST_TRANSPORT_SUCCESS = 'REQUEST_TRANSPORT_SUCCESS';
export const REQUEST_TRANSPORT_FAILURE = 'REQUEST_TRANSPORT_FAILURE';

const defaultState = {
    fetchingCheckout: false,
    failedFetchingCheckout: false,
    checkout: {},
    errors: [],
    fetchingVoucher: false,
    vouchers: {},
    fetchingTransport: false,
    failedFetchingTransport: false,
    transports: {}
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_CHECKOUT_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),

    [REQUEST_CHECKOUT_SUCCESS]: (state, action) => ({
        ...state,
        checkout: get(action, 'payload.data'),
        fetchingCheckout: false,
        failedFetchingCheckout: false
    }),

    [REQUEST_CHECKOUT]: (state, action) => ({
        ...state,
        fetchingCheckout: true,
        failedFetchingCheckout: false,
    }),

    [REQUEST_CHECKOUT_FAILURE]: (state, action) => ({
        ...state,
        fetchingCheckout: false,
        failedFetchingCheckout: true,
    }),

    [REQUEST_CHECK_VOUCHER]: (state, action) => ({
        ...state,
        fetchingVoucher: true
    }),
    [REQUEST_CHECK_VOUCHER_SUCCESS]: (state, action) => ({
        ...state,
        vouchers: get(action, 'payload.data'),
        fetchingVoucher: false
    }),
    [REQUEST_CHECK_VOUCHER_FAILURE]: (state, action) => ({
        ...state,
        vouchers: {},
        fetchingVoucher: true
    }),
    [REQUEST_TRANSPORT]: (state, action) => ({
        ...state,
        transports: {},
        fetchingTransport: true,
        failedFetchingTransport: false
    }),
    [REQUEST_TRANSPORT_SUCCESS]: (state, action) => ({
        ...state,
        transports: get(action, 'payload.data'),
        fetchingTransport: false,
        failedFetchingTransport: false
    }),
    [REQUEST_TRANSPORT_FAILURE]: (state, action) => ({
        ...state,
        transports: {},
        fetchingTransport: false,
        failedFetchingTransport: true
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_CHECKOUT_FILTER,
    payload
});

export default reducer;
export const namespace = 'checkout';

export const getVoucherSuitable = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/orders/voucher-suitable',
        actionTypes: [REQUEST_CHECK_VOUCHER, REQUEST_CHECK_VOUCHER_SUCCESS, REQUEST_CHECK_VOUCHER_FAILURE],
        variables: {...data},
        dispatch,
        getState
    });
};

export const getTransportationServices = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/seller/orders/transportation-services',
        actionTypes: [REQUEST_TRANSPORT, REQUEST_TRANSPORT_SUCCESS, REQUEST_TRANSPORT_FAILURE],
        variables: {...data},
        dispatch,
        getState
    });
};