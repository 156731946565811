import {createReducer} from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
// import { ROLE_CS } from "../../../utils/constants";
import {get} from 'lodash';

export const REQUEST_CATEGORY = 'REQUEST_CATEGORY';
export const REQUEST_CATEGORY_SUCCESS = 'REQUEST_CATEGORY_SUCCESS';
export const REQUEST_CATEGORY_FAILURE = 'REQUEST_CATEGORY_FAILURE';
export const ACTION_CHANGE_CATEGORY_FILTER = 'ACTION_CHANGE_CATEGORY_FILTER';

export const REQUEST_FULL_CATEGORY = 'REQUEST_FULL_CATEGORY';
export const REQUEST_FULL_CATEGORY_SUCCESS = 'REQUEST_FULL_CATEGORY_SUCCESS';
export const REQUEST_FULL_CATEGORY_FAILURE = 'REQUEST_FULL_CATEGORY_FAILURE';

const defaultState = {
    fetchingCategory: false,
    failedFetchingCategory: false,
    category: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    filter: {
        search_key: "",
        page: 1,
    },
    errors: [],
    fetchingFullCategory: false,
    failedFetchingFullCategory: false,
    fullCateChill: {}
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_CATEGORY_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),

    [REQUEST_CATEGORY_SUCCESS]: (state, action) => ({
        ...state,
        category: get(action, 'payload.data'),
        fetchingCategory: false,
        failedFetchingCategory: false
    }),

    [REQUEST_CATEGORY]: (state, action) => ({
        ...state,
        fetchingCategory: true,
        failedFetchingCategory: false,
    }),

    [REQUEST_CATEGORY_FAILURE]: (state, action) => ({
        ...state,
        fetchingCategory: false,
        failedFetchingCategory: true,
    }),

    [REQUEST_FULL_CATEGORY]: (state, action) => ({
        ...state,
        fetchingFullCategory: false,
        failedFetchingFullCategory: true,
    }),

    [REQUEST_FULL_CATEGORY_SUCCESS]: (state, action) => ({
        ...state,
        fullCateChill: get(action, 'payload.data'),
        fetchingFullCategory: false,
        failedFetchingFullCategory: true,
    }),

    [REQUEST_FULL_CATEGORY_FAILURE]: (state, action) => ({
        ...state,
        fullCateChill: {},
        fetchingFullCategory: true,
        failedFetchingFullCategory: true,
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_CATEGORY_FILTER,
    payload
});

export default reducer;
export const namespace = 'category';

export const getCategories = (params = {page: 1, limit: 100}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/categories',
        actionTypes: [REQUEST_CATEGORY, REQUEST_CATEGORY_SUCCESS, REQUEST_CATEGORY_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const getFullCategoryById = (categoryId) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/categories/' + categoryId,
        actionTypes: [REQUEST_FULL_CATEGORY, REQUEST_FULL_CATEGORY_SUCCESS, REQUEST_FULL_CATEGORY_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};