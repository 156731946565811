import {
    fork, all, put
} from 'redux-saga/effects';
import {getSlides, topContributors} from "../home";

function* loadRouteData() {
    yield put(getSlides())
    yield put(topContributors({limit: 25}))
}

function* handleListenActions() {
}

export function* loadTopSellerAwardedPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions)
    ]);
}
