import React from 'react';
import styles from './styles.module.scss'
import { Spin } from 'antd';
export default class Loading extends React.Component {
  render() {
    return (
        <div className={styles.loadingPageWrap}>
          <Spin tip="Đang tải ..."></Spin>
        </div>
    );
  }
}
