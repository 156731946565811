import { isFunction } from 'lodash';
import { getAuthToken } from './localStorage';
import axios from 'axios';
import { redirect } from 'redux-first-router';
import {
  ROUTE_LOGIN,
  selectRouteType
} from '../state/modules/routing';

export default async function callAPI({
    method,
    apiPath,
    actionTypes: [requestType, successType, failureType],
    variables,
    dispatch,
    getState,
    headers
}) {
  if (!isFunction(dispatch) || !isFunction(getState)) {
    throw new Error('callGraphQLApi requires dispatch and getState functions');
  }

  const baseUrlApi = process.env.REACT_APP_API_URL;
  const token = getAuthToken();
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
  }
  dispatch({ type: requestType, meta: { variables } });
  // const isAuthRoute = apiPath.indexOf('login/info') >= 0 || apiPath.endsWith('login');
  return axios({
    baseURL: baseUrlApi,
    headers: headers ? {...headers, ...header} : header,
    method,
    url: apiPath,
    data: variables,
    params: method === 'get' ? variables : ''
  })
  .then(function (response) {
    dispatch({ type: successType, meta: { variables }, payload: response.data });
    return response.data;
  })
  .catch(function (error) {
    let response = error.response ? error.response : error;
    if (response.status === 401) {
      const currentState = getState();
      const currentType = selectRouteType(currentState);
      dispatch(redirect({
        type: ROUTE_LOGIN,
        query: currentType !== ROUTE_LOGIN ? { redirect: currentType } : {}
      }));
    }
    dispatch({ type: failureType, meta: { variables }, payload: error ? error.response : null });
    return {
      errorCode: response.status,
      errorMessage: response.statusText
    };
  });
}
