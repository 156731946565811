import {NOT_FOUND} from 'redux-first-router';
import {
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_REGISTER,
    ROUTE_CART,
    ROUTE_ORDER,
    ROUTE_ORDER_DETAIL,
    ROUTE_ACCOUNT,
    ROUTE_PRODUCT_DETAIL,
    ROUTE_CATEGORY,
    ROUTE_CHECKOUT,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_RESET_PASSWORD,
    ROUTE_CUSTOMER,
    ROUTE_PRODUCT_LIST,
    ROUTE_CONTNET,
    ROUTE_NOTIFICATION,
    DOCS_FOR_COLLABORATORS,
    DOCS_FOR_COLLABORATOR_DETAIL,
    ROUTE_WELCOME,
    ROUTE_VOUCHER,
    ROUTE_MY_ACCOUNT_PROFILE,
    ROUTE_MY_ACCOUNT_BANK,
    ROUTE_MY_ACCOUNT_ADDRESS,
    ROUTE_MENU_LEFT_MOBILE,
    ROUTE_MY_ACCOUNT_CHANGE_PASSWORD,
    ROUTE_BONUS,
    ROUTE_BONUS_DETAIL,
    ROUTE_BONUS_RULES,
    ROUTE_BONUS_INVITATION_LIST,
    ROUTE_TOP_SELLER_AWARDED,
    ROUTE_SALES_GROUP,
    ROUTE_CONTENT_APP,
    ROUTE_BLOGS,
    ROUTE_SALES_GROUP_DETAIL,
    ROUTE_ORDER_THANK_YOU
} from './state/modules/routing';
import {loadHomePage} from './state/modules/home/saga';
import {loadCartPage} from './state/modules/cart/saga';
import {loadOrderPage} from './state/modules/order/saga';
import {loadOrderDetailPage} from './state/modules/orderDetail/saga';
import { loadOrderThankYouPage } from './state/modules/orderThankYou/saga';
import {loadAccountPage} from './state/modules/account/saga';
import {loadProductPage} from './state/modules/product/saga';
import {loadCategoryPage} from './state/modules/category/saga';
import {loadCheckoutPage} from './state/modules/checkout/saga';
import {loadCustomerPage} from './state/modules/customer/saga';
import {loadProductListPage} from "./state/modules/productList/saga";
import {loadContentPage} from "./state/modules/content/saga";
import {loadDocsPage} from "./state/modules/docs/saga";
import {loadVoucherPage} from "./state/modules/voucher/saga";
import {loadAccountProfilePage} from "./state/modules/accountProfile/saga";
import {loadAccountBankPage} from "./state/modules/accountBank/saga";
import {loadAccountAddressPage} from "./state/modules/accountAddress/saga";
import {loadAccountChangePasswordPage} from "./state/modules/accountChangePassword/saga";
import {loadBlogsPage} from "./state/modules/blogs/saga";
import {loadAffiliatePage} from "./state/modules/affiliate/saga";
import {loadBonusInvitationListPage} from "./state/modules/bonusInvitationList/saga";
import {loadTopSellerAwardedPage} from "./state/modules/topSellerAwarded/saga";
import {loadContentAppPage} from "./state/modules/contentApp/saga";
import {loadSaleGroupPage} from "./state/modules/salesGroup/saga";

export default {
    [NOT_FOUND]: {
        path: '/not-found',
        component: 'NotFound',
    },

    [ROUTE_LOGIN]: {
        path: '/login',
        component: 'Login',
        requiresAuth: false
    },

    [ROUTE_REGISTER]: {
        path: '/register',
        component: 'Register',
        requiresAuth: false
    },

    [ROUTE_FORGOT_PASSWORD]: {
        path: '/forgot-password',
        component: 'ForgotPassword',
        requiresAuth: false
    },

    [ROUTE_RESET_PASSWORD]: {
        path: '/reset-password/:token',
        component: 'ResetPassword',
        requiresAuth: false
    },

    [ROUTE_HOME]: {
        path: '/',
        component: 'Home',
        saga: loadHomePage,
        requiresAuth: true
    },

    [ROUTE_CART]: {
        path: '/cart',
        component: 'Cart',
        saga: loadCartPage,
        requiresAuth: true
    },

    [ROUTE_ORDER]: {
        path: '/order',
        component: 'Order',
        saga: loadOrderPage,
        requiresAuth: true
    },

    [ROUTE_ORDER_DETAIL]: {
        path: '/order/:id',
        component: 'OrderDetail',
        saga: loadOrderDetailPage,
        requiresAuth: true
    },

    [ROUTE_ORDER_THANK_YOU]: {
        path: '/thank-you/:id',
        component: 'OrderOverview',
        saga: loadOrderThankYouPage,
        requiresAuth: true
    },

    [ROUTE_ACCOUNT]: {
        path: '/user/account',
        component: 'Account',
        saga: loadAccountPage,
        requiresAuth: true
    },

    [ROUTE_PRODUCT_DETAIL]: {
        path: '/product/:id',
        component: 'ProductDetail',
        saga: loadProductPage,
        requiresAuth: true
    },

    [ROUTE_CUSTOMER]: {
        path: '/customer',
        component: 'Customer',
        saga: loadCustomerPage,
        requiresAuth: true
    },

    [ROUTE_CATEGORY]: {
        path: '/category/:id',
        component: 'Category',
        saga: loadCategoryPage,
        requiresAuth: true
    },

    [ROUTE_CONTNET]: {
        path: '/content/:id',
        component: 'Content',
        saga: loadContentPage,
        requiresAuth: false
    },

    [ROUTE_CHECKOUT]: {
    path: '/checkout',
        component: 'Checkout',
        saga: loadCheckoutPage,
        requiresAuth: true
    },

    [ROUTE_PRODUCT_LIST]: {
        path: '/products',
        component: 'ProductList',
        saga: loadProductListPage,
        requiresAuth: true
    },

    //docs for collaborators
     [DOCS_FOR_COLLABORATORS]: {
        path: '/danh-cho-ctv',
        component: 'DocsCollaborators',
        requiresAuth: false
     },

     [DOCS_FOR_COLLABORATOR_DETAIL]: {
        path: '/danh-cho-ctv/:id',
        component: 'DocsCollaboratorDetail',
        requiresAuth: false,
        saga: loadDocsPage,
     },

     [ROUTE_NOTIFICATION]: {
        path: '/notification',
        component: 'Notification',
        requiresAuth: true
     },

    [ROUTE_VOUCHER]: {
        path: '/voucher',
        component: 'Voucher',
        requiresAuth: true,
        saga: loadVoucherPage,
     },

    [ROUTE_MY_ACCOUNT_PROFILE]: {
        path: '/account/profile',
        component: 'AccountProfile',
        requiresAuth: true,
        saga: loadAccountProfilePage,
    },

    [ROUTE_MY_ACCOUNT_BANK]: {
        path: '/account/bank',
        component: 'AccountBank',
        requiresAuth: true,
        saga: loadAccountBankPage,
    },

    [ROUTE_MY_ACCOUNT_ADDRESS]: {
        path: '/account/address',
        component: 'AccountAddress',
        requiresAuth: true,
        saga: loadAccountAddressPage,
    },

    [ROUTE_MY_ACCOUNT_CHANGE_PASSWORD]: {
        path: '/account/change-password',
        component: 'AccountChangePassword',
        requiresAuth: true,
        saga: loadAccountChangePasswordPage,
    },

    [ROUTE_WELCOME]: {
        path: '/welcome',
        component: 'Welcome',
        // requiresAuth: true,
    },

    [ROUTE_MENU_LEFT_MOBILE]: {
        path: '/user-info',
        component: 'UserMenuMobile',
        requiresAuth: true
    },

    [ROUTE_BLOGS]: {
        path: '/blogs/:slug',
        component: 'Blogs',
        requiresAuth: false,
        saga: loadBlogsPage
    },

    [ROUTE_BONUS]: {
        path: '/bonus',
        component: 'Bonus',
        requiresAuth: true,
        saga: loadAffiliatePage
    },

    [ROUTE_BONUS_DETAIL]: {
        path: '/bonus/detail',
        component: 'BonusDetail',
        requiresAuth: true,
        saga: loadAffiliatePage
    },

    [ROUTE_BONUS_RULES]: {
        path: '/affiliate/rules',
        component: 'BonusRules',
        requiresAuth: true,
        saga: loadAffiliatePage
    },

    [ROUTE_BONUS_INVITATION_LIST]: {
        path: '/bonus/invitation-list',
        component: 'BonusInvitationList',
        requiresAuth: true,
        saga: loadBonusInvitationListPage
    },

    [ROUTE_TOP_SELLER_AWARDED]: {
        path: '/bonus/top-seller-awarded',
        component: 'TopSellerAwarded',
        requiresAuth: true,
        saga: loadTopSellerAwardedPage
    },

    [ROUTE_SALES_GROUP]: {
        path: '/sales-group',
        component: 'SalesGroup',
        requiresAuth: true,
        saga: loadSaleGroupPage
    },

    [ROUTE_SALES_GROUP_DETAIL]: {
        path: '/sales-group/:id',
        component: 'SalesGroup',
        requiresAuth: true,
        saga: loadSaleGroupPage
    },

    [ROUTE_CONTENT_APP]: {
        path: '/app/content/:slug',
        component: 'ContentApp',
        saga: loadContentAppPage,
        requiresAuth: false
    }
};
