import { createReducer } from 'redux-create-reducer';
import callAPI from "../../../utils/callAPI";
import {get} from "lodash";

export const REQUEST_DOCS = 'REQUEST_DOCS';
export const REQUEST_DOCS_SUCCESS = 'REQUEST_DOCS_SUCCESS';
export const REQUEST_DOCS_FAILURE = 'REQUEST_DOCS_FAILURE';

export const REQUEST_DOCS_CATEGORIES = 'REQUEST_DOCS_CATEGORIES';
export const REQUEST_DOCS_CATEGORIES_SUCCESS = 'REQUEST_DOCS_CATEGORIES_SUCCESS';
export const REQUEST_DOCS_CATEGORIES_FAILURE = 'REQUEST_DOCS_CATEGORIES_FAILURE';

const defaultState = {

};
const reducer = createReducer(defaultState, {
    [REQUEST_DOCS_SUCCESS]: (state, action) => ({
        ...state,
        docs: get(action, 'payload.data'),
        fetchingDocs: false,
        failedFetchingDocs: false
    }),
    [REQUEST_DOCS_CATEGORIES_SUCCESS]: (state, action) => ({
        ...state,
        docsCategories: get(action, 'payload.data'),
        fetchingDocs: false,
        failedFetchingDocs: false
    }),
});

export const getDocsBySlug = (slug) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/page_contents/docs',
        actionTypes: [REQUEST_DOCS, REQUEST_DOCS_SUCCESS, REQUEST_DOCS_FAILURE],
        variables: {slug},
        dispatch,
        getState
    });
};

export const getDocsCategories = (slug) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/page_contents/docs-categories',
        actionTypes: [REQUEST_DOCS_CATEGORIES, REQUEST_DOCS_CATEGORIES_SUCCESS, REQUEST_DOCS_CATEGORIES_FAILURE],
        variables: {slug},
        dispatch,
        getState
    });
};

export default reducer;
export const namespace = 'docs';
