import {
    all,
    put,
    fork,
    takeLatest, select
} from 'redux-saga/effects';

import {
    getProfile,
    getCommission,
    ACTION_CHANGE_COMMISSION_FILTER,
    REQUEST_UPDATE_ACCOUNT_SUCCESS,
    REQUEST_UPDATE_ACCOUNT_FAILURE,
    REQUEST_WITHDRAWAL_SUCCESS,
    REQUEST_WITHDRAWAL_FAILURE
} from './index';


import { showToastAlert } from "../app";
import * as localStorage from 'utils/localStorage';

function* loadRouteData() {
    yield put(getProfile())
    yield put(getCommission())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_COMMISSION_FILTER,
    ], function* (action) {
        const {account} = yield select();
        const {
            filter
        } = account;
        yield put(getCommission(filter))
    });
}

function* handleUpdateAccountSuccess() {
    yield takeLatest(REQUEST_UPDATE_ACCOUNT_SUCCESS, function* (action) {
        // update access token when change password
        if(action.payload.data.access_token) {
            localStorage.setAuthToken(action.payload.data.access_token);
        }
        yield put(showToastAlert('Cập nhật thông tin hồ sơ thành công'))
        yield put(getProfile())
    });
}

function* handleUpdateAccountFailure() {
    yield takeLatest(REQUEST_UPDATE_ACCOUNT_FAILURE, function* (action) {
        yield put(showToastAlert('Cập nhật thông tin hồ sơ thất bại', 'error'))
        yield put(getProfile())
    });
}

function* handleWithdrawalFailure() {
    yield takeLatest(REQUEST_WITHDRAWAL_FAILURE, function* (action) {
        yield put(showToastAlert('Tạo lệnh rút tiền thất bại', 'error'))
        yield put(getProfile())
    });
}

function* handleWithdrawalSuccess() {
    yield takeLatest(REQUEST_WITHDRAWAL_SUCCESS, function* (action) {
        yield put(showToastAlert('Tạo lệnh rút tiền thành công. Vui lòng chờ duyệt'))
        yield put(getProfile())
    });
}

export function* loadAccountPage() {
    yield all([
        fork(loadRouteData),
        fork(handleUpdateAccountSuccess),
        fork(handleUpdateAccountFailure),
        fork(handleListenActions),
        fork(handleWithdrawalSuccess),
        fork(handleWithdrawalFailure)
    ]);
}