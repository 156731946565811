import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import appReducer, { namespace as appNamespace } from './modules/app';
import authReducer, { namespace as authNamespace } from './modules/auth';
import homeReducer, { namespace as homeNamespace } from './modules/home';
import categoryReducer, { namespace as categoryNamespace } from './modules/category';
import productReducer, { namespace as productNamespace } from './modules/product';
import accountReducer, { namespace as accountNamespace } from './modules/account';
import customerReducer, { namespace as customerNamespace } from './modules/customer';
import storeReducer, { namespace as storeNamespace } from './modules/store';
import cartReducer, { namespace as cartNamespace } from './modules/cart';
import orderReducer, { namespace as orderNamespace } from './modules/order';
import orderDetailReducer, { namespace as orderDetailNamespace } from './modules/orderDetail';
import orderThankYouReducer, { namespace as orderThankYouNamespace } from './modules/orderThankYou';
import contentReducer, { namespace as contentNamespace } from './modules/content';
import productListReducer, { namespace as productListNamespace } from './modules/productList';
import docsReducer, { namespace as docsNamespace } from './modules/docs';
import checkoutReducer, { namespace as checkoutNamespace } from './modules/checkout';
import notificationReducer, { namespace as notificationNamespace } from './modules/notification';
import voucherReducer, { namespace as voucherNamespace } from './modules/voucher';
import blogsReducer, { namespace as blogsNamespace } from './modules/blogs';
import bonusInvitationListReducer, { namespace as bonusInvitationListNamespace } from './modules/bonusInvitationList';
import affiliateReducer, { namespace as affiliateNamespace } from './modules/affiliate';
import policyReducer, { namespace as policyNamespace } from './modules/contentApp';
import saleGroupReducer, { namespace as saleGroupNamespace } from './modules/salesGroup';

export default extraReducers =>
  combineReducers({
    [authNamespace]: authReducer,
    [appNamespace]: appReducer,
    [homeNamespace]: homeReducer,
    [categoryNamespace]: categoryReducer,
    [productNamespace]: productReducer,
    [accountNamespace]: accountReducer,
    [customerNamespace]: customerReducer,
    [storeNamespace]: storeReducer,
    [cartNamespace]: cartReducer,
    [orderNamespace]: orderReducer,
    [orderDetailNamespace]: orderDetailReducer,
    [orderThankYouNamespace]: orderThankYouReducer,
    [contentNamespace]: contentReducer,
    [productListNamespace]: productListReducer,
    [docsNamespace]: docsReducer,
    [checkoutNamespace]: checkoutReducer,
    [notificationNamespace]: notificationReducer,
    [voucherNamespace]: voucherReducer,
    [blogsNamespace]: blogsReducer,
    [bonusInvitationListNamespace]: bonusInvitationListReducer,
    [affiliateNamespace]: affiliateReducer,
    [policyNamespace]: policyReducer,
    [saleGroupNamespace]: saleGroupReducer,
    form: formReducer,
    ...extraReducers
  });
