import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';

import { get } from 'lodash';

export const REQUEST_ORDERS = 'REQUEST_ORDERS';
export const REQUEST_ORDERS_FAILURE = 'REQUEST_ORDERS_FAILURE';
export const ACTION_CHANGE_ORDER_FILTER = 'ACTION_CHANGE_ORDER_FILTER';

export const REQUEST_ALL_ORDERS_SUCCESS = 'REQUEST_ALL_ORDERS_SUCCESS';

export const REQUEST_CANCEL_ORDER = 'REQUEST_CANCEL_ORDER';
export const REQUEST_CANCEL_ORDER_SUCCESS = 'REQUEST_CANCEL_ORDER_SUCCESS';
export const REQUEST_CANCEL_ORDER_FAILURE = 'REQUEST_CANCEL_ORDER_FAILURE';


const defaultState = {
  fetchingOrders: false,
  failedFetchingOrders: false,
  allOrders: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
  [REQUEST_ORDERS]: (state, action) => ({
    ...state,
    fetchingOrders: true
  }),
  [REQUEST_ALL_ORDERS_SUCCESS]: (state, action) => ({
    ...state,
    allOrders: get(action, 'payload.data'),
    fetchingOrders: false
  }),
  [REQUEST_ORDERS_FAILURE]: (state, action) => ({
    ...state,
    allOrders: {},
    fetchingOrders: true
  }),
  [REQUEST_CANCEL_ORDER]: (state, action) => ({
    ...state,
    fetchingOrders: true
  }),
  [REQUEST_CANCEL_ORDER_SUCCESS]: (state, action) => ({
    ...state,
    fetchingOrders: false
  }),
  [REQUEST_CANCEL_ORDER_FAILURE]: (state, action) => ({
    ...state,
    fetchingOrders: true
  }),
  [ACTION_CHANGE_ORDER_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload')
  }),
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_ORDER_FILTER,
  payload
});

export default reducer;
export const namespace = 'order';

export const getAllOrders = (params = { page: 1, limit: 20, sortBy:'updated_at:desc' }) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/seller/orders',
    actionTypes: [REQUEST_ORDERS, REQUEST_ALL_ORDERS_SUCCESS, REQUEST_ORDERS_FAILURE],
    variables: { ...params },
    dispatch,
    getState
  });
};

export const cancelOrder = (orderId) => async (dispatch, getState) => {
  return await callAPI({
    method: 'patch',
    apiPath: '/seller/orders/' + orderId + '/cancel',
    actionTypes: [REQUEST_CANCEL_ORDER, REQUEST_CANCEL_ORDER_SUCCESS, REQUEST_CANCEL_ORDER_FAILURE],
    variables: {},
    dispatch,
    getState
  });
};