import {
    fork, all, put, takeLatest, select
} from 'redux-saga/effects';
import {ACTION_CHANGE_BONUS_INVITATIONS_FILTER, getInvitations} from "./index";

function* loadRouteData() {
    yield put(getInvitations())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_BONUS_INVITATIONS_FILTER
    ], function* (action) {
        const {bonusInvitations} = yield select();
        const {
            filter
        } = bonusInvitations;
        yield put(getInvitations(filter))
    });
}

export function* loadBonusInvitationListPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions)
    ]);
}
