import {
  all,
  put,
  fork,
  takeLatest
} from 'redux-saga/effects';

import {
  getNotifications,
  REQUEST_READ_NOTIFICATION_SUCCESS,
  REQUEST_READ_NOTIFICATION_FAILURE,
} from './index';

import {
  fetchUserInfor,
} from '../auth/index'

function* loadRouteData() {
  yield put(getNotifications())
}

function* handleReadNotificationSuccess() {
  yield takeLatest(REQUEST_READ_NOTIFICATION_SUCCESS, function* (action) {
    yield put(fetchUserInfor());
  });
}

function* handleReadNotificationFailure() {
  yield takeLatest(REQUEST_READ_NOTIFICATION_FAILURE, function* (action) {
  });
}

export default function* loadNotificationPage() {
  yield all([
    fork(loadRouteData),
    fork(handleReadNotificationSuccess),
    fork(handleReadNotificationFailure)
  ]);
}
