import {
    put,
    fork, select, all,
} from 'redux-saga/effects';

import {
    getCategoryContent,
    getContentById,
} from './index'

function* loadRouteData() {
    yield put(getCategoryContent())
    const {location} = yield select();
    if (location.payload.id) {
        yield put(getContentById(location.payload.id));
    }
}

export function* loadContentPage() {
    yield all([
        fork(loadRouteData)
    ]);
}
