import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
// import { ROLE_CS } from "../../../utils/constants";
import { get } from 'lodash';
export const REQUEST_CUSTOMER = 'REQUEST_CUSTOMER';
export const REQUEST_CUSTOMER_SUCCESS = 'REQUEST_CUSTOMER_SUCCESS';
export const REQUEST_CUSTOMER_FAILURE = 'REQUEST_CUSTOMER_FAILURE';

export const REQUEST_CREATE_CUSTOMER = 'REQUEST_CREATE_CUSTOMER';
export const REQUEST_CREATE_CUSTOMER_SUCCESS = 'REQUEST_CREATE_CUSTOMER_SUCCESS';
export const REQUEST_CREATE_CUSTOMER_FAILURE = 'REQUEST_CREATE_CUSTOMER_FAILURE';

export const REQUEST_UPDATE_CUSTOMER = 'REQUEST_UPDATE_CUSTOMER';
export const REQUEST_UPDATE_CUSTOMER_SUCCESS = 'REQUEST_UPDATE_CUSTOMER_SUCCESS';
export const REQUEST_UPDATE_CUSTOMER_FAILURE = 'REQUEST_UPDATE_CUSTOMER_FAILURE';

export const REQUEST_DELETE_CUSTOMER         = 'REQUEST_DELETE_CUSTOMER';
export const REQUEST_DELETE_CUSTOMER_SUCCESS = 'REQUEST_DELETE_CUSTOMER_SUCCESS';
export const REQUEST_DELETE_CUSTOMER_FAILURE = 'REQUEST_DELETE_CUSTOMER_FAILURE';

export const REQUEST_CREATE_ADDRESS = 'REQUEST_CREATE_ADDRESS';
export const REQUEST_CREATE_ADDRESS_SUCCESS = 'REQUEST_CREATE_ADDRESS_SUCCESS';
export const REQUEST_CREATE_ADDRESS_FAILURE = 'REQUEST_CREATE_ADDRESS_FAILURE';

export const REQUEST_UPDATE_ADDRESS = 'REQUEST_UPDATE_ADDRESS';
export const REQUEST_UPDATE_ADDRESS_SUCCESS = 'REQUEST_UPDATE_ADDRESS_SUCCESS';
export const REQUEST_UPDATE_ADDRESS_FAILURE = 'REQUEST_UPDATE_ADDRESS_FAILURE';

export const REQUEST_DELETE_ADDRESS = 'REQUEST_DELETE_ADDRESS';
export const REQUEST_DELETE_ADDRESS_SUCCESS = 'REQUEST_DELETE_ADDRESS_SUCCESS';
export const REQUEST_DELETE_ADDRESS_FAILURE = 'REQUEST_DELETE_ADDRESS_FAILURE';

export const ACTION_CHANGE_CUSTOMER_FILTER = 'ACTION_CHANGE_CUSTOMER_FILTER';

const defaultState = {
  fetchingCustomer: false,
  failedFetchingCustomer: false,
  customers: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_CUSTOMER_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
    }),

    [REQUEST_CUSTOMER_SUCCESS]: (state, action) => ({
        ...state,
        customers: get(action, 'payload.data'),
        fetchingCustomer: false,
        failedFetchingCustomer: false
    }),

    [REQUEST_CUSTOMER]: (state, action) => ({
        ...state,
        fetchingCustomer: true,
        failedFetchingCustomer: false,
    }),

    [REQUEST_CUSTOMER_FAILURE]: (state, action) => ({
        ...state,
        fetchingCustomer: false,
        failedFetchingCustomer: true,
    }),
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_CUSTOMER_FILTER,
  payload
});

export default reducer;
export const namespace = 'customers';


export const getCustomers = (params = {page: 1, sortBy:'belong_to_user:desc,updated_at:desc'}) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/seller/customers',
    actionTypes: [REQUEST_CUSTOMER, REQUEST_CUSTOMER_SUCCESS, REQUEST_CUSTOMER_FAILURE],
    variables: {...params},
    dispatch,
    getState
  });
};

export const createCustomer = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/seller/customers',
    actionTypes: [REQUEST_CREATE_CUSTOMER, REQUEST_CREATE_CUSTOMER_SUCCESS, REQUEST_CREATE_CUSTOMER_FAILURE],
    variables: {
        phone_number: data.phone_number,
        full_name: data.full_name,
        belong_to_user: data.belong_to_user,
        address_list: data.address_list
    },
    dispatch,
    getState
  });
};

export const updateCustomer = (data, customer_id) => async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: '/seller/customers/'+customer_id,
    actionTypes: [REQUEST_UPDATE_CUSTOMER, REQUEST_UPDATE_CUSTOMER_SUCCESS, REQUEST_UPDATE_CUSTOMER_FAILURE],
    variables: {
        phone_number: data.phone,
        full_name: data.name,
        belong_to_user: data.belong_to_user
    },
    dispatch,
    getState
  });
};

export const deleteCustomer = (customer_id) => async (dispatch, getState) => {
  return await callAPI({
    method: 'delete',
    apiPath: '/seller/customers/'+customer_id,
    actionTypes: [REQUEST_DELETE_CUSTOMER, REQUEST_DELETE_CUSTOMER_SUCCESS, REQUEST_DELETE_CUSTOMER_FAILURE],
    variables: {},
    dispatch,
    getState
  });
};

export const createAdrrdess = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/seller/customers/address/'+data.customer_id,
    actionTypes: [REQUEST_CREATE_ADDRESS, REQUEST_CREATE_ADDRESS_SUCCESS, REQUEST_CREATE_ADDRESS_FAILURE],
    variables: {
        province: data.province,
        district: data.district,
        ward: data.ward,
        address: data.address,
        full_address: data.full_address
    },
    dispatch,
    getState
  });
};

export const updateAdrrdess = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: '/seller/customers/address/'+data.customer_id,
    actionTypes: [REQUEST_UPDATE_ADDRESS, REQUEST_UPDATE_ADDRESS_SUCCESS, REQUEST_UPDATE_ADDRESS_FAILURE],
    variables: {
        _id: data.id,
        province: data.province,
        district: data.district,
        ward: data.ward,
        address: data.address,
        full_address: data.full_address
    },
    dispatch,
    getState
  });
};

export const deleteAdrrdess = (customer_id, address_id) => async (dispatch, getState) => {
  return await callAPI({
    method: 'delete',
    apiPath: '/seller/customers/' + customer_id +'/address/' + address_id,
    actionTypes: [REQUEST_DELETE_ADDRESS, REQUEST_DELETE_ADDRESS_SUCCESS, REQUEST_DELETE_ADDRESS_FAILURE],
    variables: {},
    dispatch,
    getState
  });
};
