import {
  all,
  put,
  fork,
  takeLatest,
  select
} from 'redux-saga/effects';

import {
  getCustomers,
  REQUEST_CREATE_CUSTOMER_FAILURE,
  REQUEST_CREATE_CUSTOMER_SUCCESS,
  REQUEST_UPDATE_CUSTOMER_FAILURE,
  REQUEST_UPDATE_CUSTOMER_SUCCESS,
  REQUEST_DELETE_CUSTOMER_FAILURE,
  REQUEST_DELETE_CUSTOMER_SUCCESS,
  REQUEST_CREATE_ADDRESS_FAILURE,
  REQUEST_CREATE_ADDRESS_SUCCESS,
  REQUEST_UPDATE_ADDRESS_FAILURE,
  REQUEST_UPDATE_ADDRESS_SUCCESS,
  REQUEST_DELETE_ADDRESS_FAILURE,
  REQUEST_DELETE_ADDRESS_SUCCESS,
  ACTION_CHANGE_CUSTOMER_FILTER
} from './index'
import { showToastAlert } from "../app";

function* loadRouteData() {
    yield put(getCustomers())
}

function* handleListenActions() {
  yield takeLatest([
    ACTION_CHANGE_CUSTOMER_FILTER
  ], function* (action) {
    const {customers} = yield select();
    const {
      filter
    } = customers;
    yield put(getCustomers(filter))
  });
}

function* handleCreateCustomerSuccess() {
  yield takeLatest(REQUEST_CREATE_CUSTOMER_SUCCESS, function* (action) {
    yield put(showToastAlert('tạo mới khách hàng thành công'))
    yield put(getCustomers())
  });
}

function* handleCreateCustomerFailure() {
  yield takeLatest(REQUEST_CREATE_CUSTOMER_FAILURE, function* (action) {
    yield put(showToastAlert('tạo mới khách hàng thất bại, số điện thoại đã tồn tại ', 'error'))
    yield put(getCustomers())
  });
}

function* handleUpdateCustomerSuccess() {
  yield takeLatest(REQUEST_UPDATE_CUSTOMER_SUCCESS, function* (action) {
    yield put(showToastAlert('cập nhật khách hàng thành công'))
    yield put(getCustomers())
  });
}

function* handleUpdateCustomerFailure() {
  yield takeLatest(REQUEST_UPDATE_CUSTOMER_FAILURE, function* (action) {
    yield put(showToastAlert('cập nhật khách hàng thất bại, số điện thoại đã tồn tại ', 'error'))
    yield put(getCustomers())
  });
}

function* handleDeleteCustomerSuccess() {
  yield takeLatest(REQUEST_DELETE_CUSTOMER_SUCCESS, function* (action) {
    yield put(showToastAlert('xóa khách hàng thành công'))
    yield put(getCustomers())
  });
}

function* handleDeleteCustomerFailure() {
  yield takeLatest(REQUEST_DELETE_CUSTOMER_FAILURE, function* (action) {
    yield put(showToastAlert('xóa khách hàng thất bại, số điện thoại đã tồn tại ', 'error'))
    yield put(getCustomers())
  });
}

function* handleCreateAddressSuccess() {
  yield takeLatest(REQUEST_CREATE_ADDRESS_SUCCESS, function* (action) {
    yield put(showToastAlert('tạo mới địa chỉ thành công'))
    yield put(getCustomers())
  });
}

function* handleCreateAddressFailure() {
  yield takeLatest(REQUEST_CREATE_ADDRESS_FAILURE, function* (action) {
    yield put(showToastAlert('tạo mới địa thất bại', 'error'))
    yield put(getCustomers())
  });
}

function* handleUpdateAddressSuccess() {
  yield takeLatest(REQUEST_UPDATE_ADDRESS_SUCCESS, function* (action) {
    yield put(showToastAlert('sửa địa chỉ thành công'))
    yield put(getCustomers())
  });
}

function* handleUpdateAddressFailure() {
  yield takeLatest(REQUEST_UPDATE_ADDRESS_FAILURE, function* (action) {
    yield put(showToastAlert('sửa địa thất bại', 'error'))
    yield put(getCustomers())
  });
}

function* handleDeleteAddressSuccess() {
  yield takeLatest(REQUEST_DELETE_ADDRESS_SUCCESS, function* (action) {
    yield put(showToastAlert('xóa địa chỉ thành công'))
    yield put(getCustomers())
  });
}

function* handleDeleteAddressFailure() {
  yield takeLatest(REQUEST_DELETE_ADDRESS_FAILURE, function* (action) {
    yield put(showToastAlert('xóa địa thất bại', 'error'))
    yield put(getCustomers())
  });
}

export function* loadCustomerPage() {
  yield all([
    fork(loadRouteData),
    fork(handleListenActions),
    fork(handleCreateCustomerSuccess),
    fork(handleCreateCustomerFailure),
    fork(handleUpdateCustomerSuccess),
    fork(handleUpdateCustomerFailure),
    fork(handleDeleteCustomerSuccess),
    fork(handleDeleteCustomerFailure),
    fork(handleCreateAddressSuccess),
    fork(handleCreateAddressFailure),
    fork(handleUpdateAddressSuccess),
    fork(handleUpdateAddressFailure),
    fork(handleDeleteAddressSuccess),
    fork(handleDeleteAddressFailure),
  ]);
}
  