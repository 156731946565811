import {
    all,
    put,
    fork, takeLatest, select,
} from 'redux-saga/effects';

import {
    getVouchers,
    getVouchersOfUser,
    REQUEST_SAVE_VOUCHER_SUCCESS,
    REQUEST_SAVE_VOUCHER_FAILURE,
    ACTION_CHANGE_PAGE_VOUCHER_FILTER,
    ACTION_CHANGE_PAGE_VOUCHERS_FILTER
} from './index'

import {showToastAlert} from "../app";
import {fetchUserInfor} from "../auth";

function* loadRouteData() {
    yield put(fetchUserInfor());
    yield put(getVouchers());
    yield put(getVouchersOfUser());
}

function* handleSaveVoucherForUserFailure() {
    yield takeLatest(REQUEST_SAVE_VOUCHER_FAILURE, function* (action) {
        const {voucher} = yield select();
        if (voucher.error && voucher.error.message) {
            yield put(showToastAlert(`${voucher.error.message}`, 'error'))
        } else {
            yield put(showToastAlert('Lưu voucher thất bại!', 'error'))
        }
    });
}

function* handleSaveVoucherForUserSuccess() {
    yield takeLatest(REQUEST_SAVE_VOUCHER_SUCCESS, function* (action) {
        yield put(showToastAlert('Lưu voucher thành công!'));
        yield put(fetchUserInfor());
        yield put(getVouchers());
        yield put(getVouchersOfUser());
    });
}

function* handleListenActionFilterVoucherUser() {
    yield takeLatest([
        ACTION_CHANGE_PAGE_VOUCHER_FILTER
    ], function* (action) {
        const {voucher} = yield select();
        const {
            filter
        } = voucher;
        yield put(fetchUserInfor());
        yield put(getVouchersOfUser(filter));
    });
}

function* handleListenActionFilterVouchers() {
    yield takeLatest([
        ACTION_CHANGE_PAGE_VOUCHERS_FILTER
    ], function* (action) {
        const {voucher} = yield select();
        const {
            filter
        } = voucher;
        yield put(fetchUserInfor());
        yield put(getVouchers(filter));
    });
}

export function* loadVoucherPage() {
    yield all([
        fork(loadRouteData),
        fork(handleSaveVoucherForUserFailure),
        fork(handleSaveVoucherForUserSuccess),
        fork(handleListenActionFilterVoucherUser),
        fork(handleListenActionFilterVouchers),
    ]);
}
