import {
    all,
    //put,
    fork, put, select, takeLatest,
} from 'redux-saga/effects';
import {REQUEST_CREATE_CART_FAILURE, REQUEST_CREATE_CART_SUCCESS} from "../cart";
import {showToastAlert} from "../app";
import {redirect} from "redux-first-router";
import { ROUTE_ORDER_THANK_YOU } from "../routing";
import {getCustomers} from "../customer";
import {filterProductChecked} from "../../../utils/helper";
import { getVoucherSuitable } from "./index";

//import {getOrder,} from './index'

function* loadRouteData() {
    yield put(getCustomers())
    const {totalPaySupplier} = filterProductChecked();
    yield put(getVoucherSuitable({totalPay: totalPaySupplier}))
}

function* handleCreateCartSuccess() {
    yield takeLatest(REQUEST_CREATE_CART_SUCCESS, function* (action) {
        if (Array.isArray(action.payload.data)) {
            // goto page order list
            yield put(redirect({
                type: ROUTE_ORDER_THANK_YOU,
                payload: { id: action.payload.data.join('_') }
            }));
        } else {
            // go to vnpay payment url
            window.location.assign(action.payload.data);
        }
    });
}

function* handleCreateCartFailure() {
    yield takeLatest(REQUEST_CREATE_CART_FAILURE, function* (action) {
        const {cart} = yield select();
        if (cart.error && cart.error.message) {
            yield put(showToastAlert(`${cart.error.message}`, 'error'))
        } else {
            yield put(showToastAlert('Tạo đơn hàng thất bại', 'error'))
        }
    });
}

export function* loadCheckoutPage() {
    yield all([
        fork(loadRouteData),
        fork(handleCreateCartSuccess),
        fork(handleCreateCartFailure),
    ]);
}