import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_PROGRAM_SALES_GROUP = 'REQUEST_PROGRAM_SALES_GROUP';
export const REQUEST_PROGRAM_SALES_GROUP_SUCCESS = 'REQUEST_PROGRAM_SALES_GROUP_SUCCESS';
export const REQUEST_PROGRAM_SALES_GROUP_FAILURE = 'REQUEST_PROGRAM_SALES_GROUP_FAILURE';

export const REQUEST_ADD_SALES_GROUP = 'REQUEST_ADD_SALES_GROUP';
export const REQUEST_ADD_SALES_GROUP_SUCCESS = 'REQUEST_ADD_SALES_GROUP_SUCCESS';
export const REQUEST_ADD_SALES_GROUP_FAILURE = 'REQUEST_ADD_SALES_GROUP_FAILURE';

export const REQUEST_SALES_GROUP = 'REQUEST_SALES_GROUP';
export const REQUEST_SALES_GROUP_SUCCESS = 'REQUEST_SALES_GROUP_SUCCESS';
export const REQUEST_SALES_GROUP_FAILURE = 'REQUEST_SALES_GROUP_FAILURE';

export const REQUEST_SALE_GROUP_DETAIL = 'REQUEST_SALE_GROUP_DETAIL';
export const REQUEST_SALE_GROUP_DETAIL_SUCCESS = 'REQUEST_SALE_GROUP_DETAIL_SUCCESS';
export const REQUEST_SALE_GROUP_DETAIL_FAILURE = 'REQUEST_SALE_GROUP_DETAIL_FAILURE';

export const REQUEST_JOIN_SALE_GROUP = 'REQUEST_JOIN_SALE_GROUP';
export const REQUEST_JOIN_SALE_GROUP_SUCCESS = 'REQUEST_JOIN_SALE_GROUP_SUCCESS';
export const REQUEST_JOIN_SALE_GROUP_FAILURE = 'REQUEST_JOIN_SALE_GROUP_FAILURE';

export const REQUEST_UN_JOIN_SALE_GROUP = 'REQUEST_UN_JOIN_SALE_GROUP';
export const REQUEST_UN_JOIN_SALE_GROUP_SUCCESS = 'REQUEST_UN_JOIN_SALE_GROUP_SUCCESS';
export const REQUEST_UN_JOIN_SALE_GROUP_FAILURE = 'REQUEST_UN_JOIN_SALE_GROUP_FAILURE';

export const REQUEST_JOINT_SALE_GROUP_OF_USER = 'REQUEST_JOINT_SALE_GROUP_OF_USER';
export const REQUEST_JOINT_SALE_GROUP_OF_USER_SUCCESS = 'REQUEST_JOINT_SALE_GROUP_OF_USER_SUCCESS';
export const REQUEST_JOINT_SALE_GROUP_OF_USER_FAILURE = 'REQUEST_JOINT_SALE_GROUP_OF_USER_FAILURE';

export const REQUEST_SALE_GROUP_JOINED = 'REQUEST_SALE_GROUP_JOINED';
export const REQUEST_SALE_GROUP_JOINED_SUCCESS = 'REQUEST_SALE_GROUP_JOINED_SUCCESS';
export const REQUEST_SALE_GROUP_JOINED_FAILURE = 'REQUEST_SALE_GROUP_JOINED_FAILURE';

export const REQUEST_POSITION_MEMBER = 'REQUEST_POSITION_MEMBER';
export const REQUEST_POSITION_MEMBER_SUCCESS = 'REQUEST_POSITION_MEMBER_SUCCESS';
export const REQUEST_POSITION_MEMBER_FAILURE = 'REQUEST_POSITION_MEMBER_FAILURE';

export const ACTION_CHANGE_SALE_GROUP_FILTER = 'ACTION_CHANGE_SALE_GROUP_FILTER';

export const REQUEST_GET_MEMBER_OF_GROUP = 'REQUEST_GET_MEMBER_OF_GROUP';
export const REQUEST_GET_MEMBER_OF_GROUP_SUCCESS = 'REQUEST_GET_MEMBER_OF_GROUP_SUCCESS';
export const REQUEST_GET_MEMBER_OF_GROUP_FAILURE = 'REQUEST_GET_MEMBER_OF_GROUP_FAILURE';

export const REQUEST_JOIN_REQUESTS_OF_GROUP = 'REQUEST_JOIN_REQUESTS_OF_GROUP';
export const REQUEST_JOIN_REQUESTS_OF_GROUP_SUCCESS = 'REQUEST_JOIN_REQUESTS_OF_GROUP_SUCCESS';
export const REQUEST_JOIN_REQUESTS_OF_GROUP_FAILURE = 'REQUEST_JOIN_REQUESTS_OF_GROUP_FAILURE';

export const REQUEST_UPDATE_STATUS_JOIN_REQUEST = 'REQUEST_UPDATE_STATUS_JOIN_REQUEST';
export const REQUEST_UPDATE_STATUS_JOIN_REQUEST_SUCCESS = 'REQUEST_UPDATE_STATUS_JOIN_REQUEST_SUCCESS';
export const REQUEST_UPDATE_STATUS_JOIN_REQUEST_FAILURE = 'REQUEST_UPDATE_STATUS_JOIN_REQUEST_FAILURE';

export const REQUEST_UPDATE_POSITION_MEMBER = 'REQUEST_UPDATE_POSITION_MEMBER';
export const REQUEST_UPDATE_POSITION_MEMBER_SUCCESS = 'REQUEST_UPDATE_POSITION_MEMBER_SUCCESS';
export const REQUEST_UPDATE_POSITION_MEMBER_FAILURE = 'REQUEST_UPDATE_POSITION_MEMBER_FAILURE';

export const REQUEST_GET_OUT_GROUP = 'REQUEST_GET_OUT_GROUP';
export const REQUEST_GET_OUT_GROUP_SUCCESS = 'REQUEST_GET_OUT_GROUP_SUCCESS';
export const REQUEST_GET_OUT_GROUP_FAILURE = 'REQUEST_GET_OUT_GROUP_FAILURE';

export const REQUEST_KICK_MEMBER = 'REQUEST_KICK_MEMBER';
export const REQUEST_KICK_MEMBER_SUCCESS = 'REQUEST_KICK_MEMBER_SUCCESS';
export const REQUEST_KICK_MEMBER_FAILURE = 'REQUEST_KICK_MEMBER_FAILURE';

const defaultState = {
    fetchingProgramSalesGroup: false,
    failedFetchingProgramSalesGroup: false,
    programSalesGroup: {},
    fetchingSalesGroup: false,
    failedFetchingSalesGroup: false,
    salesGroup: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    fetchingSaleGroup: false,
    failedFetchingSaleGroup: false,
    saleGroupJoined: {},
    fetchingSaleGroupJoined: false,
    failedFetchingSaleGroupJoined: false,
    group: {},
    fetchingGetRequestJoinSaleGroup: false,
    failedFetchingGetRequestJoinSaleGroup: false,
    members: {},
    fetchingMembers: false,
    failedFetchingMembers: false,
    requests: {},
    fetchingRequests: false,
    failedFetchingRequests: false,
    requestJoin: {},
    positionMember: null,
    fetchingPositionMember: false,
    failedFetchingPositionMember: false,
    error: {},
    filter: {}
};

const reducer = createReducer(defaultState, {
    [REQUEST_PROGRAM_SALES_GROUP]: (state, action) => ({
        ...state,
        fetchingProgramSalesGroup: true,
        failedFetchingProgramSalesGroup: false,
    }),

    [REQUEST_PROGRAM_SALES_GROUP_SUCCESS]: (state, action) => ({
        ...state,
        programSalesGroup: get(action, 'payload.data'),
        fetchingProgramSalesGroup: false,
        failedFetchingProgramSalesGroup: false
    }),

    [REQUEST_PROGRAM_SALES_GROUP_FAILURE]: (state, action) => ({
        ...state,
        fetchingProgramSalesGroup: false,
        failedFetchingProgramSalesGroup: true
    }),

    [REQUEST_SALES_GROUP]: (state, action) => ({
        ...state,
        fetchingSalesGroup: true,
        failedFetchingSalesGroup: false,
    }),

    [REQUEST_SALES_GROUP_SUCCESS]: (state, action) => ({
        ...state,
        salesGroup: get(action, 'payload.data'),
        fetchingSalesGroup: false,
        failedFetchingSalesGroup: false
    }),

    [REQUEST_SALES_GROUP_FAILURE]: (state, action) => ({
        ...state,
        fetchingSalesGroup: false,
        failedFetchingSalesGroup: true
    }),

    [REQUEST_SALE_GROUP_DETAIL]: (state, action) => ({
        ...state,
        fetchingSaleGroup: true,
        failedFetchingSaleGroup: false,
    }),

    [REQUEST_SALE_GROUP_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        group: get(action, 'payload.data'),
        fetchingSaleGroup: false,
        failedFetchingSaleGroup: false
    }),

    [REQUEST_SALE_GROUP_DETAIL_FAILURE]: (state, action) => ({
        ...state,
        fetchingSaleGroup: false,
        failedFetchingSaleGroup: true
    }),

    [REQUEST_ADD_SALES_GROUP]: (state, action) => ({
        ...state,
        fetchingProgramSalesGroup: true,
        failedFetchingProgramSalesGroup: false,
    }),

    [REQUEST_JOINT_SALE_GROUP_OF_USER]: (state, action) => ({
        ...state,
        fetchingGetRequestJoinSaleGroup: true,
        failedFetchingGetRequestJoinSaleGroup: false,
    }),

    [REQUEST_JOINT_SALE_GROUP_OF_USER_SUCCESS]: (state, action) => ({
        ...state,
        requestJoin: get(action, 'payload.data'),
        fetchingGetRequestJoinSaleGroup: false,
        failedFetchingGetRequestJoinSaleGroup: false
    }),

    [REQUEST_JOINT_SALE_GROUP_OF_USER_FAILURE]: (state, action) => ({
        ...state,
        fetchingGetRequestJoinSaleGroup: false,
        failedFetchingGetRequestJoinSaleGroup: true
    }),

    [REQUEST_SALE_GROUP_JOINED]: (state, action) => ({
        ...state,
        fetchingSaleGroupJoined: true,
        failedFetchingSaleGroupJoined: false,
    }),

    [REQUEST_SALE_GROUP_JOINED_SUCCESS]: (state, action) => ({
        ...state,
        saleGroupJoined: get(action, 'payload.data'),
        fetchingSaleGroupJoined: false,
        failedFetchingSaleGroupJoined: false,
    }),

    [REQUEST_SALE_GROUP_JOINED_FAILURE]: (state, action) => ({
        ...state,
        saleGroupJoined: get(action, 'payload.data'),
        fetchingSaleGroupJoined: false,
        failedFetchingSaleGroupJoined: true,
    }),

    [REQUEST_POSITION_MEMBER]: (state, action) => ({
        ...state,
        fetchingPositionMember: true,
        failedFetchingPositionMember: false,
    }),

    [REQUEST_POSITION_MEMBER_SUCCESS]: (state, action) => ({
        ...state,
        positionMember: get(action, 'payload.data'),
        fetchingPositionMember: false,
        failedFetchingPositionMember: false,
    }),

    [REQUEST_POSITION_MEMBER_FAILURE]: (state, action) => ({
        ...state,
        positionMember: get(action, 'payload.data'),
        fetchingPositionMember: false,
        failedFetchingPositionMember: true,
    }),

    [ACTION_CHANGE_SALE_GROUP_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload')
    }),

    [REQUEST_GET_MEMBER_OF_GROUP]: (state, action) => ({
        ...state,
        fetchingMembers: true,
        failedFetchingMembers: false,
    }),

    [REQUEST_GET_MEMBER_OF_GROUP_SUCCESS]: (state, action) => ({
        ...state,
        members: get(action, 'payload.data'),
        fetchingMembers: false,
        failedFetchingMembers: false,
    }),

    [REQUEST_GET_MEMBER_OF_GROUP_FAILURE]: (state, action) => ({
        ...state,
        members: get(action, 'payload.data'),
        fetchingMembers: false,
        failedFetchingMembers: true,
    }),

    [REQUEST_JOIN_REQUESTS_OF_GROUP]: (state, action) => ({
        ...state,
        fetchingRequests: true,
        failedFetchingRequests: false,
    }),

    [REQUEST_JOIN_REQUESTS_OF_GROUP_SUCCESS]: (state, action) => ({
        ...state,
        requests: get(action, 'payload.data'),
        fetchingRequests: false,
        failedFetchingRequests: false,
    }),

    [REQUEST_JOIN_REQUESTS_OF_GROUP_FAILURE]: (state, action) => ({
        ...state,
        requests: get(action, 'payload.data'),
        fetchingRequests: false,
        failedFetchingRequests: true,
    }),

});

export default reducer;
export const namespace = 'saleGroup';

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_SALE_GROUP_FILTER,
    payload
});

export const getProgramSalesGroup = () => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/sale_group',
        actionTypes: [REQUEST_PROGRAM_SALES_GROUP, REQUEST_PROGRAM_SALES_GROUP_SUCCESS, REQUEST_PROGRAM_SALES_GROUP_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const getSalesGroup = (params = { page: 1, limit: 20, sortBy:'total_revenue:desc'}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/sale_group/list',
        actionTypes: [REQUEST_SALES_GROUP, REQUEST_SALES_GROUP_SUCCESS, REQUEST_SALES_GROUP_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const getSaleGroupJoined = () => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/sale_group/joined',
        actionTypes: [REQUEST_SALE_GROUP_JOINED, REQUEST_SALE_GROUP_JOINED_SUCCESS, REQUEST_SALE_GROUP_JOINED_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const getPositionMember = () => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/sale_group/position',
        actionTypes: [REQUEST_POSITION_MEMBER, REQUEST_POSITION_MEMBER_SUCCESS, REQUEST_POSITION_MEMBER_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const getSaleGroupDetail = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/sale_group/' + id,
        actionTypes: [REQUEST_SALE_GROUP_DETAIL, REQUEST_SALE_GROUP_DETAIL_SUCCESS, REQUEST_SALE_GROUP_DETAIL_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const getRequestJoinSaleGroupOfUser = () => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/sale_group/user',
        actionTypes: [REQUEST_JOINT_SALE_GROUP_OF_USER, REQUEST_JOINT_SALE_GROUP_OF_USER_SUCCESS, REQUEST_JOINT_SALE_GROUP_OF_USER_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const saveSalesGroup = (data) => async (dispatch, getState) => {
    const variables = Object.fromEntries(
        ['name', 'description', 'avatar', 'chat_group_url']
            .filter(key => key in data)
            .map(key => [key, data[key]])
    );
    return await callAPI({
        method: 'post',
        apiPath: '/seller/sale_group',
        actionTypes: [REQUEST_ADD_SALES_GROUP, REQUEST_ADD_SALES_GROUP_SUCCESS, REQUEST_ADD_SALES_GROUP_FAILURE],
        variables: variables,
        dispatch,
        getState
    });
};

export const joinSaleGroup = (data) => async (dispatch, getState) => {
    const variables = Object.fromEntries(
        ['note', 'sale_group']
            .filter(key => key in data)
            .map(key => [key, data[key]])
    );
    return await callAPI({
        method: 'post',
        apiPath: '/seller/sale_group/join',
        actionTypes: [REQUEST_JOIN_SALE_GROUP, REQUEST_JOIN_SALE_GROUP_SUCCESS, REQUEST_JOIN_SALE_GROUP_FAILURE],
        variables: variables,
        dispatch,
        getState
    });
};

export const unJoinSaleGroup = (saleGroupId) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/seller/sale_group/un-join/' + saleGroupId,
        actionTypes: [REQUEST_UN_JOIN_SALE_GROUP, REQUEST_UN_JOIN_SALE_GROUP_SUCCESS, REQUEST_UN_JOIN_SALE_GROUP_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const getMemberOfGroup = (salesGroupId) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/sale_group/members/' + salesGroupId,
        actionTypes: [REQUEST_GET_MEMBER_OF_GROUP, REQUEST_GET_MEMBER_OF_GROUP_SUCCESS, REQUEST_GET_MEMBER_OF_GROUP_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const getJoinRequestsOfGroup = (salesGroupId) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/sale_group/join-requests/' + salesGroupId,
        actionTypes: [REQUEST_JOIN_REQUESTS_OF_GROUP, REQUEST_JOIN_REQUESTS_OF_GROUP_SUCCESS, REQUEST_JOIN_REQUESTS_OF_GROUP_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const updateStatusJoinRequest = (sellerId, status, saleGroupId) => async (dispatch, getState) => {
    return await callAPI({
        method: 'patch',
        apiPath: '/seller/sale_group/members/update-status',
        actionTypes: [REQUEST_UPDATE_STATUS_JOIN_REQUEST, REQUEST_UPDATE_STATUS_JOIN_REQUEST_SUCCESS, REQUEST_UPDATE_STATUS_JOIN_REQUEST_FAILURE],
        variables: {
            seller: sellerId,
            status: status,
            sale_group: saleGroupId
        },
        dispatch,
        getState
    });
};

export const updatePositionMember = (sellerId, saleGroupId, position) => async (dispatch, getState) => {
    return await callAPI({
        method: 'patch',
        apiPath: '/seller/sale_group/members/update-position',
        actionTypes: [REQUEST_UPDATE_POSITION_MEMBER, REQUEST_UPDATE_POSITION_MEMBER_SUCCESS, REQUEST_UPDATE_POSITION_MEMBER_FAILURE],
        variables: {
            seller: sellerId,
            position: position,
            sale_group: saleGroupId
        },
        dispatch,
        getState
    });
};

export const outGroup = (salesGroupId) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/seller/sale_group/get-out/' + salesGroupId,
        actionTypes: [REQUEST_GET_OUT_GROUP, REQUEST_GET_OUT_GROUP_SUCCESS, REQUEST_GET_OUT_GROUP_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const kickMemberOfGroup = (sellerId, salesGroupId) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/seller/sale_group/kick',
        actionTypes: [REQUEST_KICK_MEMBER, REQUEST_KICK_MEMBER_SUCCESS, REQUEST_KICK_MEMBER_FAILURE],
        variables: {
            seller: sellerId,
            sale_group: salesGroupId,
        },
        dispatch,
        getState
    });
};
