import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_NOTIFICATION = 'REQUEST_NOTIFICATION';
export const REQUEST_NOTIFICATION_SUCCESS = 'REQUEST_NOTIFICATION_SUCCESS';
export const REQUEST_NOTIFICATION_FAILURE = 'REQUEST_NOTIFICATION_FAILURE';

export const REQUEST_READ_NOTIFICATION = 'REQUEST_READ_NOTIFICATION';
export const REQUEST_READ_NOTIFICATION_SUCCESS = 'REQUEST_READ_NOTIFICATION_SUCCESS';
export const REQUEST_READ_NOTIFICATION_FAILURE = 'REQUEST_READ_NOTIFICATION_FAILURE';

const defaultState = {
  fetchingNotification: false,
  failedFetchingNotification: false,
  notifications: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
  [REQUEST_NOTIFICATION_SUCCESS]: (state, action) => ({
    ...state,
    notifications: get(action, 'payload.data'),
    fetchingNotification: false,
    failedFetchingNotification: false
  }),
  [REQUEST_NOTIFICATION]: (state, action) => ({
    ...state,
    fetchingNotification: true,
    failedFetchingNotification: false,
  }),
  [REQUEST_NOTIFICATION_FAILURE]: (state, action) => ({
    ...state,
    fetchingNotification: false,
    failedFetchingNotification: true,
  })
});

export default reducer;
export const namespace = 'notifications';

export const getNotifications = (params = { page: 1, limit: 100, sortBy: 'updated_at:desc' }) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get', 
    apiPath: '/seller/notifications',
    actionTypes: [REQUEST_NOTIFICATION, REQUEST_NOTIFICATION_SUCCESS, REQUEST_NOTIFICATION_FAILURE],
    variables: { ...params },
    dispatch,
    getState
  });
};

export const readNotification = (notification_id) => async (dispatch, getState) => {
  return await callAPI({
    method: 'patch',
    apiPath: `/seller/notifications/${notification_id}/read`,
    actionTypes: [REQUEST_READ_NOTIFICATION, REQUEST_READ_NOTIFICATION_SUCCESS, REQUEST_READ_NOTIFICATION_FAILURE],
    variables: {
      read: true
    },
    dispatch,
    getState
  });
};
