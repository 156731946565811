import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
// import { ROLE_CS } from "../../../utils/constants";
import { get } from 'lodash';
export const REQUEST_STORE = 'REQUEST_STORE';
export const REQUEST_STORE_SUCCESS = 'REQUEST_STORE_SUCCESS';
export const REQUEST_STORE_FAILURE = 'REQUEST_STORE_FAILURE';
export const ACTION_CHANGE_STORE_FILTER = 'ACTION_CHANGE_STORE_FILTER';

const defaultState = {
    fetchingStore: false,
    failedFetchingStore: false,
    store: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_STORE_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),

    [REQUEST_STORE_SUCCESS]: (state, action) => ({
        ...state,
        store: get(action, 'payload.data'),
        fetchingStore: false,
        failedFetchingStore: false
    }),

    [REQUEST_STORE]: (state, action) => ({
        ...state,
        fetchingStore: true,
        failedFetchingStore: false,
    }),

    [REQUEST_STORE_FAILURE]: (state, action) => ({
        ...state,
        fetchingStore: false,
        failedFetchingStore: true,
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_STORE_FILTER,
    payload
});

export default reducer;
export const namespace = 'store';


export const getStores = (params = {page: 1, sortBy:'updated_at:desc'}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/stores',
        actionTypes: [REQUEST_STORE, REQUEST_STORE_SUCCESS, REQUEST_STORE_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};