import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_ACCOUNTS = 'REQUEST_ACCOUNTS';
export const REQUEST_ACCOUNTS_SUCCESS = 'REQUEST_ACCOUNTS_SUCCESS';
export const REQUEST_ACCOUNTS_FAILURE = 'REQUEST_ACCOUNTS_FAILURE';

export const ACTION_CHANGE_COMMISSION_FILTER = 'ACTION_CHANGE_COMMISSION_FILTER';
export const REQUEST_COMMISSIONS = 'REQUEST_COMMISSIONS';
export const REQUEST_COMMISSIONS_SUCCESS = 'REQUEST_COMMISSIONS_SUCCESS';
export const REQUEST_COMMISSIONS_FAILURE = 'REQUEST_COMMISSIONS_FAILURE';

export const REQUEST_UPDATE_ACCOUNT = 'REQUEST_UPDATE_ACCOUNT';
export const REQUEST_UPDATE_ACCOUNT_SUCCESS = 'REQUEST_UPDATE_ACCOUNT_SUCCESS';
export const REQUEST_UPDATE_ACCOUNT_FAILURE = 'REQUEST_UPDATE_ACCOUNT_FAILURE';

export const REQUEST_WITHDRAWAL = 'REQUEST_WITHDRAWAL';
export const REQUEST_WITHDRAWAL_SUCCESS = 'REQUEST_WITHDRAWAL_SUCCESS';
export const REQUEST_WITHDRAWAL_FAILURE = 'REQUEST_WITHDRAWAL_FAILURE';

const defaultState = {
    fetchingProfile: false,
    failedFetchingProfile: false,
    profile: null,
    commissions: [],
    errors: [],
    filter: {
        type: "",
        page: 1,
    },
};

const reducer = createReducer(defaultState, {
    [REQUEST_ACCOUNTS_SUCCESS]: (state, action) => ({
        ...state,
        profile: get(action, 'payload.data'),
        fetchingProfile: false,
        failedFetchingProfile: false
    }),

    [REQUEST_ACCOUNTS]: (state, action) => ({
        ...state,
        fetchingProfile: true,
        failedFetchingProfile: false
    }),

    [REQUEST_ACCOUNTS_FAILURE]: (state, action) => ({
        ...state,
        fetchingProfile: false,
        failedFetchingProfile: true
    }),

    [REQUEST_COMMISSIONS_SUCCESS]: (state, action) => ({
        ...state,
        commissions: get(action, 'payload.data')
    }),

    [ACTION_CHANGE_COMMISSION_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
});

export default reducer;
export const namespace = 'account';

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_COMMISSION_FILTER,
    payload
});

export const getProfile = (params = {page: 1}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/auth/me',
        actionTypes: [REQUEST_ACCOUNTS, REQUEST_ACCOUNTS_SUCCESS, REQUEST_ACCOUNTS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const updateProfile = (data) => async (dispatch, getState) => {
    const variables = Object.fromEntries(
        ['password', 'first_name', 'last_name', 'sex', 'date_of_birth', 'address', 'bank', 'account_number', 'account_name', 'branch', 'username']
        .filter(key => key in data)
        .map(key => [key, data[key]])
      );
    return await callAPI({
        method: 'put',
        apiPath: '/seller/auth/me',
        actionTypes: [REQUEST_UPDATE_ACCOUNT, REQUEST_UPDATE_ACCOUNT_SUCCESS, REQUEST_UPDATE_ACCOUNT_FAILURE],
        variables: variables,
        dispatch,
        getState
    });
};

export const getCommission = (params = {page: 1, sortBy:'updated_at:desc'}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/commission',
        actionTypes: [REQUEST_COMMISSIONS, REQUEST_COMMISSIONS_SUCCESS, REQUEST_COMMISSIONS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const requestWithdrawals = (params) => async (dispatch, getState) => {
    console.log(params)
    return await callAPI({
        method: 'post',
        apiPath: '/seller/withdrawal-orders',
        actionTypes: [REQUEST_WITHDRAWAL, REQUEST_WITHDRAWAL_SUCCESS, REQUEST_WITHDRAWAL_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};
