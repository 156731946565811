import {
    all,
    put,
    fork,
    select, takeLatest,
} from 'redux-saga/effects';

import {
    getProductById,
    getProducts,
    REQUEST_PRODUCT_DETAIL_SUCCESS,
    REQUEST_CHECKING_INVENTORY_FAILURE
} from './index';

import {getStores} from "../store";
import {showToastAlert} from "../app";
import {getAllVouchers, REQUEST_SAVE_VOUCHER_FAILURE, REQUEST_SAVE_VOUCHER_SUCCESS} from "../voucher";
import {fetchUserInfor} from "../auth";
//import {redirect} from "redux-first-router";
//import {ROUTE_CART} from "../routing";

function* loadRouteData() {
    const {location} = yield select();
    if (location.payload.id) {
      yield put(getProductById(location.payload.id));
    }
    yield put(getStores({ page: 1, sortBy: 'name:asc' }));
    yield put(getAllVouchers());
}

function* handleGetRelatedProducts() {
    yield takeLatest(REQUEST_PRODUCT_DETAIL_SUCCESS, function* (action) {
        const {product} = yield select();
        yield put(getProducts({category_id: product.product.categories}));
    });
}

function* handleCheckQuantityFailure() {
    yield takeLatest(REQUEST_CHECKING_INVENTORY_FAILURE, function* (action) {
        yield put(showToastAlert('Không tìm thấy sản phẩm trong kho', 'error'))
    });
}

function* handleSaveVoucherInProductDetailSuccess() {
    yield takeLatest(REQUEST_SAVE_VOUCHER_SUCCESS, function* (action) {
        yield put(showToastAlert('Lưu voucher thành công'));
        yield put(fetchUserInfor());
    });
}

function* handleSaveVoucherInProductDetailFailure() {
    yield takeLatest(REQUEST_SAVE_VOUCHER_FAILURE, function* (action) {
        yield put(showToastAlert('Lưu voucher không thành công', 'error'));
    });
}

/*function* redirectToPageCart() {
    yield takeLatest(REQUEST_CHECKING_INVENTORY_SUCCESS, function* (action) {
        console.log('action :', action)
        const {product} = yield select();
        // if (product.resultCheckQuantityProduct) {
        //     yield put(redirect({
        //         type: ROUTE_CART,
        //         query: ROUTE_CART
        //     }));
        // }
    });
}*/

export function* loadProductPage() {
  yield all([
    fork(loadRouteData),
    fork(handleGetRelatedProducts),
    fork(handleCheckQuantityFailure),
    fork(handleSaveVoucherInProductDetailSuccess),
    fork(handleSaveVoucherInProductDetailFailure),
    // fork(redirectToPageCart)
  ]);
}
  