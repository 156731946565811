import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
// import { ROLE_CS } from "../../../utils/constants";
import { get } from 'lodash';
import useMobileDetect from "use-mobile-detect-hook";

export const REQUEST_VOUCHERS = 'REQUEST_VOUCHERS';
export const REQUEST_VOUCHERS_SUCCESS = 'REQUEST_VOUCHERS_SUCCESS';
export const REQUEST_VOUCHERS_FAILURE = 'REQUEST_VOUCHERS_FAILURE';

export const REQUEST_ALL_VOUCHERS = 'REQUEST_ALL_VOUCHERS';
export const REQUEST_ALL_VOUCHERS_SUCCESS = 'REQUEST_ALL_VOUCHERS_SUCCESS';
export const REQUEST_ALL_VOUCHERS_FAILURE = 'REQUEST_ALL_VOUCHERS_FAILURE';

export const REQUEST_VOUCHERS_OF_SELLER = 'REQUEST_VOUCHERS_OF_SELLER';
export const REQUEST_VOUCHERS_OF_SELLER_SUCCESS = 'REQUEST_VOUCHERS_OF_SELLER_SUCCESS';
export const REQUEST_VOUCHERS_OF_SELLER_FAILURE = 'REQUEST_VOUCHERS_OF_SELLER_FAILURE';

export const REQUEST_SAVE_VOUCHER = 'REQUEST_SAVE_VOUCHER';
export const REQUEST_SAVE_VOUCHER_SUCCESS = 'REQUEST_SAVE_VOUCHER_SUCCESS';
export const REQUEST_SAVE_VOUCHER_FAILURE = 'REQUEST_SAVE_VOUCHER_FAILURE';

export const ACTION_CHANGE_PAGE_VOUCHER_FILTER = 'ACTION_CHANGE_PAGE_VOUCHER_FILTER';
export const ACTION_CHANGE_PAGE_VOUCHERS_FILTER = 'ACTION_CHANGE_PAGE_VOUCHERS_FILTER';

const detectMobile = useMobileDetect();

const defaultState = {
    fetchingVoucher: false,
    failedFetchingVoucher: false,
    fetchingVoucherOfSeller: false,
    failedFetchingVoucherOfSeller: false,
    fetchingSaveVoucher: false,
    fetchingAllVouchers: false,
    failedFetchingAllVouchers: false,
    vouchers: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    voucherUsers: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    allVouchers: {},
    filter: {
        search_key: "",
        page: 1,
    },
    errors: [],
    error: {}
};

const reducer = createReducer(defaultState, {
    [REQUEST_VOUCHERS]: (state, action) => ({
        ...state,
        fetchingVoucher: true,
        failedFetchingVoucher: false,
    }),
    [REQUEST_VOUCHERS_SUCCESS]: (state, action) => ({
        ...state,
        vouchers: get(action, 'payload.data'),
        fetchingVoucher: false,
        failedFetchingVoucher: false,
    }),
    [REQUEST_VOUCHERS_FAILURE]: (state, action) => ({
        ...state,
        fetchingVoucher: false,
        failedFetchingVoucher: true,
    }),

    [REQUEST_VOUCHERS_OF_SELLER]: (state, action) => ({
        ...state,
        fetchingVoucherOfSeller: true,
        failedFetchingVoucherOfSeller: false,
    }),
    [REQUEST_VOUCHERS_OF_SELLER_SUCCESS]: (state, action) => ({
        ...state,
        voucherUsers: get(action, 'payload.data'),
        fetchingVoucherOfSeller: false,
        failedFetchingVoucherOfSeller: false,
    }),
    [REQUEST_VOUCHERS_OF_SELLER_FAILURE]: (state, action) => ({
        ...state,
        fetchingVoucherOfSeller: false,
        failedFetchingVoucherOfSeller: true,
    }),

    [REQUEST_SAVE_VOUCHER]: (state, action) => ({
        ...state,
        fetchingSaveVoucher: true,
        error: {}
    }),
    [REQUEST_SAVE_VOUCHER_SUCCESS]: (state, action) => ({
        ...state,
        fetchingSaveVoucher: false,
        error: {}
    }),
    [REQUEST_SAVE_VOUCHER_FAILURE]: (state, action) => ({
        ...state,
        error: get(action, 'payload.data'),
        fetchingSaveVoucher: false
    }),

    [ACTION_CHANGE_PAGE_VOUCHER_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),

    [ACTION_CHANGE_PAGE_VOUCHERS_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),

    [REQUEST_ALL_VOUCHERS]: (state, action) => ({
        ...state,
        fetchingAllVouchers: true,
        failedFetchingAllVouchers: false,
    }),
    [REQUEST_ALL_VOUCHERS_SUCCESS]: (state, action) => ({
        ...state,
        allVouchers: get(action, 'payload.data'),
        fetchingAllVouchers: false,
        failedFetchingAllVouchers: false,
    }),
    [REQUEST_ALL_VOUCHERS_FAILURE]: (state, action) => ({
        ...state,
        fetchingAllVouchers: false,
        failedFetchingAllVouchers: true,
    }),
});

export default reducer;
export const namespace = 'voucher';

export const changeFilterVoucherUser = (payload) => ({
    type: ACTION_CHANGE_PAGE_VOUCHER_FILTER,
    payload
});

export const changeFilterVouchers = (payload) => ({
    type: ACTION_CHANGE_PAGE_VOUCHERS_FILTER,
    payload
});

export const getVouchers = (params = {page: 1, limit: detectMobile.isMobile() ? 6 : 12}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/vouchers',
        actionTypes: [REQUEST_VOUCHERS, REQUEST_VOUCHERS_SUCCESS, REQUEST_VOUCHERS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const getAllVouchers = () => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/vouchers/voucher-product-detail',
        actionTypes: [REQUEST_ALL_VOUCHERS, REQUEST_ALL_VOUCHERS_SUCCESS, REQUEST_ALL_VOUCHERS_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const getVouchersOfUser = (params = {page: 1,  limit: 6}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/vouchers/voucher-user',
        actionTypes: [REQUEST_VOUCHERS_OF_SELLER, REQUEST_VOUCHERS_OF_SELLER_SUCCESS, REQUEST_VOUCHERS_OF_SELLER_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};


export const saveVoucherSeller = (params) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/seller/vouchers/save-voucher-user',
        actionTypes: [REQUEST_SAVE_VOUCHER, REQUEST_SAVE_VOUCHER_SUCCESS, REQUEST_SAVE_VOUCHER_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};


