import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_ORDER_DETAIL = 'REQUEST_ORDER_DETAIL';
export const REQUEST_ORDER_DETAIL_SUCCESS = 'REQUEST_ORDER_DETAIL_ORDER_SUCCESS';
export const REQUEST_ORDER_DETAIL_FAILURE = 'REQUEST_ORDER_DETAIL_ORDER_FAILURE';

const defaultState = {
  fetchingOrder: false,
  orders: [],
  errors: []
};

const reducer = createReducer(defaultState, {
  [REQUEST_ORDER_DETAIL]: (state, action) => ({
    ...state,
    fetchingOrder: true
  }),
  [REQUEST_ORDER_DETAIL_SUCCESS]: (state, action) => {
    // format order object to one-element-array
    let orders = get(action, 'payload.data');
    if (!Array.isArray(orders)) {
      orders = [orders];
    }
    return {
      ...state,
      orders: orders,
      fetchingOrder: false
    }
  },
  [REQUEST_ORDER_DETAIL_FAILURE]: (state, action) => ({
    ...state,
    fetchingOrder: true
  })
});

export default reducer;
export const namespace = 'orderThankYou';

export const getOrderById = (orderId) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/seller/orders/' + orderId,
    actionTypes: [REQUEST_ORDER_DETAIL, REQUEST_ORDER_DETAIL_SUCCESS, REQUEST_ORDER_DETAIL_FAILURE],
    variables: {},
    dispatch,
    getState
  });
};