import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const REQUEST_PRODUCTS_SUCCESS = 'REQUEST_PRODUCTS_SUCCESS';
export const REQUEST_PRODUCTS_FAILURE = 'REQUEST_PRODUCTS_FAILURE';

export const REQUEST_NEW_PRODUCTS = 'REQUEST_NEW_PRODUCTS';
export const REQUEST_NEW_PRODUCTS_SUCCESS = 'REQUEST_NEW_PRODUCTS_SUCCESS';
export const REQUEST_NEW_PRODUCTS_FAILURE = 'REQUEST_NEW_PRODUCTS_FAILURE';

export const REQUEST_BEST_SELL_PRODUCTS = 'REQUEST_BEST_SELL_PRODUCTS';
export const REQUEST_BEST_SELL_PRODUCTS_SUCCESS = 'REQUEST_BEST_SELL_PRODUCTS_SUCCESS';
export const REQUEST_BEST_SELL_PRODUCTS_FAILURE = 'REQUEST_BEST_SELL_PRODUCTS_FAILURE';

export const ACTION_CHANGE_PRODUCT_FILTER = 'ACTION_CHANGE_PRODUCT_FILTER';

export const REQUEST_PRODUCT_DETAIL = 'REQUEST_PRODUCT_DETAIL';
export const REQUEST_PRODUCT_DETAIL_SUCCESS = 'REQUEST_PRODUCT_DETAIL_SUCCESS';
export const REQUEST_PRODUCT_DETAIL_FAILURE = 'REQUEST_PRODUCT_DETAIL_FAILURE';

export const REQUEST_PRODUCT_BY_CATEGORY = 'REQUEST_PRODUCT_BY_CATEGORY';
export const REQUEST_PRODUCT_BY_CATEGORY_SUCCESS = 'REQUEST_PRODUCT_BY_CATEGORY_SUCCESS';
export const REQUEST_PRODUCT_BY_CATEGORY_FAILURE = 'REQUEST_PRODUCT_BY_CATEGORY_FAILURE';

export const REQUEST_CHECKING_INVENTORY = 'REQUEST_CHECKING_INVENTORY';
export const REQUEST_CHECKING_INVENTORY_SUCCESS = 'REQUEST_CHECKING_INVENTORY_SUCCESS';
export const REQUEST_CHECKING_INVENTORY_FAILURE = 'REQUEST_CHECKING_INVENTORY_FAILURE';
export const ACTION_CHANGE_PRODUCT_QUANTITY = 'ACTION_CHANGE_PRODUCT_QUANTITY';

const defaultState = {
  fetchingProducts: false,
  failedFetchingProducts: false,
  fetchingNewProducts: false,
  failedFetchingNewProducts: false,
  fetchingProduct: false,
  fetchingBestSellProducts: false,
  failedBestSellProducts: false,
  failedFetchingProduct: false,

  fetchedQuantityProduct: false,
  resultCheckQuantityProduct: true,
  showErrorQuantity: true,
  products: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  product: {},
  bestSellProducts: null,
  newProducts: {},
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_PRODUCT_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
    }),

    [REQUEST_PRODUCTS_SUCCESS]: (state, action) => ({
        ...state,
        products: get(action, 'payload.data'),
        fetchingProducts: false,
        failedFetchingProducts: false
    }),

    [REQUEST_PRODUCTS]: (state, action) => ({
        ...state,
        fetchingProducts: true,
        failedFetchingProducts: false,
    }),

    [REQUEST_PRODUCTS_FAILURE]: (state, action) => ({
        ...state,
        fetchingProducts: false,
        failedFetchingProducts: true,
    }),

    [REQUEST_NEW_PRODUCTS_SUCCESS]: (state, action) => ({
        ...state,
        newProducts: get(action, 'payload.data'),
        fetchingNewProducts: false,
        failedFetchingNewProducts: false
    }),

    [REQUEST_NEW_PRODUCTS]: (state, action) => ({
        ...state,
        fetchingNewProducts: true,
        failedFetchingNewProducts: false,
    }),

    [REQUEST_NEW_PRODUCTS_FAILURE]: (state, action) => ({
        ...state,
        fetchingNewProducts: false,
        failedFetchingNewProducts: true,
    }),

    [REQUEST_BEST_SELL_PRODUCTS]: (state, action) => ({
        ...state,
        bestSellProducts: get(action, 'payload.data')
    }),

    [REQUEST_BEST_SELL_PRODUCTS_SUCCESS]: (state, action) => ({
        ...state,
        bestSellProducts: get(action, 'payload.data')
    }),

    [REQUEST_BEST_SELL_PRODUCTS_FAILURE]: (state, action) => ({
        ...state,
        bestSellProducts: get(action, 'payload.data')
    }),

    [REQUEST_PRODUCT_DETAIL]: (state, action) => ({
        ...state,
        fetchingProduct: true,
        failedFetchingProduct: false,
    }),

    [REQUEST_PRODUCT_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        fetchingProduct: false,
        failedFetchingProduct: false,
        product: get(action, 'payload.data')
    }),

    [REQUEST_PRODUCT_DETAIL_FAILURE]: (state, action) => ({
        ...state,
        fetchingProduct: false,
        failedFetchingProduct: true,
    }),

    [REQUEST_CHECKING_INVENTORY]: (state, action) => ({
        ...state,
        resultCheckQuantityProduct: false,
        fetchedQuantityProduct: false
    }),
    [REQUEST_CHECKING_INVENTORY_SUCCESS]: (state, action) => ({
        ...state,
        resultCheckQuantityProduct: get(action, 'payload.data'),
        showErrorQuantity: get(action, 'payload.data'),
        fetchedQuantityProduct: true
    }),
    [REQUEST_CHECKING_INVENTORY_FAILURE]: (state, action) => ({
        ...state,
        resultCheckQuantityProduct: false,
        showErrorQuantity: false,
        fetchedQuantityProduct: true
    }),

    [ACTION_CHANGE_PRODUCT_QUANTITY]: (state, action) => ({
        ...state,
        // resultCheckQuantityProduct: true,
        showErrorQuantity: true
    }),

});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_PRODUCT_FILTER,
  payload
});

export const changeQuantityAddToCart = (payload) => ({
    type: ACTION_CHANGE_PRODUCT_QUANTITY,
    payload
});

export default reducer;
export const namespace = 'product';

export const getProducts = (params = {page: 1, limit:12, sortBy:'updated_at:desc'}) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/seller/products',
    actionTypes: [REQUEST_PRODUCTS, REQUEST_PRODUCTS_SUCCESS, REQUEST_PRODUCTS_FAILURE],
      variables: {...params},
    dispatch,
    getState
  });
};

export const getNewProducts = (params = {page: 1, limit:12, sortBy:'updated_at:desc'}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/products',
        actionTypes: [REQUEST_NEW_PRODUCTS, REQUEST_NEW_PRODUCTS_SUCCESS, REQUEST_NEW_PRODUCTS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const getBestSellProducts = (params = {page: 1, limit:12, sortBy:'quantity_sold:desc'}) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/seller/products',
    actionTypes: [REQUEST_BEST_SELL_PRODUCTS, REQUEST_BEST_SELL_PRODUCTS_SUCCESS, REQUEST_BEST_SELL_PRODUCTS_FAILURE],
      variables: {...params},
    dispatch,
    getState
  });
};

export const getProductById = (productId) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/products/'+productId,
        actionTypes: [REQUEST_PRODUCT_DETAIL, REQUEST_PRODUCT_DETAIL_SUCCESS, REQUEST_PRODUCT_DETAIL_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const getProductByCategoryId = (categoryId) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/products?category='+categoryId,
        actionTypes: [REQUEST_PRODUCT_BY_CATEGORY, REQUEST_PRODUCT_BY_CATEGORY_SUCCESS, REQUEST_PRODUCT_BY_CATEGORY_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const checkingInventory = (data) => async (dispatch, getState) =>{
    return await callAPI({
        method: 'post',
        apiPath: '/seller/products/checking-quantity',
        actionTypes: [REQUEST_CHECKING_INVENTORY, REQUEST_CHECKING_INVENTORY_SUCCESS, REQUEST_CHECKING_INVENTORY_FAILURE],
        variables: data,
        dispatch,
        getState
    });
}