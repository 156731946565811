import { createReducer } from 'redux-create-reducer';
import { get } from 'lodash';

export const ACTION_CHANGE_PRODUCT_LIST_FILTER = 'ACTION_CHANGE_PRODUCT_LIST_FILTER';

const defaultState = {
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_PRODUCT_LIST_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),

});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_PRODUCT_LIST_FILTER,
    payload
});

export default reducer;
export const namespace = 'productList';