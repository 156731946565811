import {
    all,
    fork,
    put,
    takeLatest
} from 'redux-saga/effects';
import {getProfile, REQUEST_UPDATE_ACCOUNT_FAILURE, REQUEST_UPDATE_ACCOUNT_SUCCESS} from "../account";
import {showToastAlert} from "../app";
import * as localStorage from 'utils/localStorage';

function* loadRouteData() {
    yield put(getProfile());
}

function* handleUpdateAccountProfileSuccess() {
    yield takeLatest(REQUEST_UPDATE_ACCOUNT_SUCCESS, function* (action) {
        // update access token when change password
        if(action.payload.data.access_token) {
            localStorage.setAuthToken(action.payload.data.access_token);
        }
        yield put(showToastAlert('Cập nhật thông tin địa chỉ thành công'))
        yield put(getProfile())
    });
}

function* handleUpdateAccountProfileFailure() {
    yield takeLatest(REQUEST_UPDATE_ACCOUNT_FAILURE, function* (action) {
        yield put(showToastAlert('Cập nhật thông tin địa chỉ thất bại', 'error'))
        yield put(getProfile())
    });
}

export function* loadAccountAddressPage() {
    yield all([
        fork(loadRouteData),
        fork(handleUpdateAccountProfileSuccess),
        fork(handleUpdateAccountProfileFailure),
    ]);
}