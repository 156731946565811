import {
    put,
    fork, select, all
} from 'redux-saga/effects';

import {
    getBlogBySlug,
} from './index'
import {getCategoryContent} from "../content";

function* loadRouteData() {
    const {location} = yield select();
    yield put(getCategoryContent())
    if (location.payload.slug) {
        yield put(getBlogBySlug(location.payload.slug));
    }
}

export function* loadContentAppPage() {
    yield all([
        fork(loadRouteData)
    ]);
}
