import {
  all,
  put,
  fork,
  select, takeLatest
} from 'redux-saga/effects';

import {
    getCategories, getFullCategoryById,
} from './index'
import {ACTION_CHANGE_PRODUCT_FILTER, getProducts} from "../product";
import {getStores} from "../store";

function* loadRouteData() {
    const {location} = yield select();
    yield put(getCategories());
    yield put(getFullCategoryById(location.payload.id));
    yield put(getProducts({category_id: location.payload.id, limit: 12, sort: 'new'}));
    yield put(getStores())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_PRODUCT_FILTER
    ], function* (action) {
        const {product} = yield select();
        const {
            filter
        } = product;

        yield put(getProducts(filter))
    });
}

export function* loadCategoryPage() {
  yield all([
    fork(loadRouteData),
    fork(handleListenActions),
  ]);
}
  