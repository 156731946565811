import {
  all,
  put,
  fork,
  select
} from 'redux-saga/effects';

import {
  getOrderById,
} from './index';

function* loadRouteData() {
  const {location} = yield select();
  if (location.payload.id) {
    yield put(getOrderById(location.payload.id));
  }
}

export function* loadOrderThankYouPage() {
  yield all([
    fork(loadRouteData)
  ]);
}
