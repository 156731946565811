import {
    all,
    put,
    fork,
    select, takeLatest
} from 'redux-saga/effects';

import {getProducts, ACTION_CHANGE_PRODUCT_FILTER} from "../product";
import {getCategories} from "../category";

function* loadRouteData() {
    yield put(getCategories())
    yield put(getProducts());
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_PRODUCT_FILTER
    ], function* (action) {
        const {product} = yield select();
        const {
            filter
        } = product;
        console.log('filter', filter);
        yield put(getProducts(filter))
    });
}

export function* loadProductListPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions),
    ]);
}
