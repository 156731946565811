import { ROLE_OWNER } from "../../../utils/constants";

export const ROUTE_HOME = 'route/ROUTE_HOME';
export const ROUTE_LOGIN = 'route/ROUTE_LOGIN';
export const ROUTE_REGISTER = 'route/ROUTE_REGISTER';
export const ROUTE_FORGOT_PASSWORD = 'route/ROUTE_FORGOT_PASSWORD';
export const ROUTE_RESET_PASSWORD = 'route/ROUTE_RESET_PASSWORD';
export const ROUTE_PERMISSION = 'route/ROUTE_PERMISSION';

export const ROUTE_CART = 'route/ROUTE_CART';
export const ROUTE_ORDER = 'route/ROUTE_ORDER';
export const ROUTE_ORDER_DETAIL = 'route/ROUTE_ORDER_DETAIL';
export const ROUTE_ORDER_THANK_YOU = 'route/ROUTE_ORDER_THANK_YOU';
export const ROUTE_PRODUCT_DETAIL = 'route/ROUTE_PRODUCT_DETAIL';
export const ROUTE_PRODUCT_LIST = 'route/ROUTE_PRODUCT_LIST';
export const ROUTE_ACCOUNT = 'route/ROUTE_ACCOUNT';
export const ROUTE_CATEGORY = 'route/ROUTE_CATEGORY';
export const ROUTE_CHECKOUT = 'route/ROUTE_CHECKOUT';

export const ROUTE_CONTENT = 'route/ROUTE_CONTENT';
export const ROUTE_CONTENT_CATEGORY = 'route/ROUTE_CONTENT_CATEGORY';
export const ROUTE_CUSTOMER = 'route/ROUTE_CUSTOMER';
export const ROUTE_CONTNET = 'route/ROUTE_CONTNET';
export const DOCS_FOR_COLLABORATORS = 'route/DOCS_FOR_COLLABORATORS';
export const DOCS_FOR_COLLABORATOR_DETAIL = 'route/DOCS_FOR_COLLABORATOR_DETAIL';
export const ROUTE_NOTIFICATION = 'route/ROUTE_NOTIFICATION';
export const ROUTE_VOUCHER = 'route/ROUTE_VOUCHER';
export const ROUTE_WELCOME = 'route/ROUTE_WELCOME';
export const ROUTE_MENU_LEFT_MOBILE = 'route/ROUTE_MENU_LEFT_MOBILE';

export const ROUTE_MY_ACCOUNT_PROFILE = 'route/ROUTE_MY_ACCOUNT_PROFILE';
export const ROUTE_MY_ACCOUNT_BANK = 'route/ROUTE_MY_ACCOUNT_BANK';
export const ROUTE_MY_ACCOUNT_ADDRESS = 'route/ROUTE_MY_ACCOUNT_ADDRESS';
export const ROUTE_MY_ACCOUNT_CHANGE_PASSWORD = 'route/ROUTE_MY_ACCOUNT_CHANGE_PASSWORD';
export const ROUTE_BLOGS = 'route/ROUTE_BLOGS';
export const ROUTE_BONUS = 'route/ROUTE_BONUS';
export const ROUTE_BONUS_DETAIL = 'route/ROUTE_BONUS_DETAIL';
export const ROUTE_BONUS_RULES = 'route/ROUTE_BONUS_RULES';
export const ROUTE_BONUS_INVITATION_LIST = 'route/ROUTE_BONUS_INVITATION_LIST';
export const ROUTE_TOP_SELLER_AWARDED = 'route/ROUTE_TOP_SELLER_AWARDED';
export const ROUTE_SALES_GROUP = 'route/ROUTE_SALES_GROUP';
export const ROUTE_SALES_GROUP_DETAIL = 'route/ROUTE_SALES_GROUP_DETAIL';
export const ROUTE_CONTENT_APP = 'route/ROUTE_CONTENT_APP';

export const selectCurrentRoutePayload = state => state.location.payload;
export const selectCurrentPage = state => state.location.pathname;
export const selectRouteType = state => state.location.type;
export const selectRoutesMap = state => state.location.routesMap;
export const selectPreviousRoute = state => state.location.prev;

export const goToPage = (routeType, payload) => ({
    type: routeType,
    payload: payload
});

export const isForbidden = (currentRouteType, role) => {
  let result = false

  if (currentRouteType === ROUTE_PERMISSION && role !== ROLE_OWNER) {
      result = true
  }

  return result
}