import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import {get} from "lodash";
// import { ROLE_CS } from "../../../utils/constants";
// import { get } from 'lodash';

export const REQUEST_CREATE_CART = 'REQUEST_CREATE_CART';
export const REQUEST_CREATE_CART_SUCCESS = 'REQUEST_CREATE_CART_SUCCESS';
export const REQUEST_CREATE_CART_FAILURE = 'REQUEST_CREATE_CART_FAILURE';

export const REQUEST_CHECKING_INVENTORY = 'REQUEST_CHECKING_INVENTORY';
export const REQUEST_CHECKING_INVENTORY_SUCCESS = 'REQUEST_CHECKING_INVENTORY_SUCCESS';
export const REQUEST_CHECKING_INVENTORY_FAILURE = 'REQUEST_CHECKING_INVENTORY_FAILURE';

export const REQUEST_VOUCHER_SUITABLE = 'REQUEST_VOUCHER_SUITABLE';
export const REQUEST_VOUCHER_SUITABLE_SUCCESS = 'REQUEST_VOUCHER_SUITABLE_SUCCESS';
export const REQUEST_VOUCHER_SUITABLE_FAILURE = 'REQUEST_VOUCHER_SUITABLE_FAILURE';

export const REQUEST_VOUCHER_PRODUCT = 'REQUEST_VOUCHER_PRODUCT';
export const REQUEST_VOUCHER_PRODUCT_SUCCESS = 'REQUEST_VOUCHER_PRODUCT_SUCCESS';
export const REQUEST_VOUCHER_PRODUCT_FAILURE = 'REQUEST_VOUCHER_PRODUCT_FAILURE';

export const REQUEST_DISCOUNTS = 'REQUEST_DISCOUNTS';
export const REQUEST_DISCOUNTS_SUCCESS = 'REQUEST_DISCOUNTS_SUCCESS';
export const REQUEST_DISCOUNTS_FAILURE = 'REQUEST_DISCOUNTS_FAILURE';

export const REQUEST_VALIDATE_CART = 'REQUEST_VALIDATE_CART';
export const REQUEST_VALIDATE_CART_SUCCESS = 'REQUEST_VALIDATE_CART_SUCCESS';
export const REQUEST_VALIDATE_CART_FAILURE = 'REQUEST_VALIDATE_CART_FAILURE';

export const ACTION_UPDATE_CART = 'ACTION_UPDATE_CART';
export const ACTION_VALIDATE_CART_DONE = 'ACTION_VALIDATE_CART_DONE';

const defaultState = {
  resultCheckQuantityProduct: true,
  error: {},
  fetchingDiscountSuitable: false,
  fetchingDiscountSuitableFail: false,
  discount: {},
  fetchingVoucherKpiProduct: false,
  fetchingVoucherKpiProductFail: false,
  voucherKpiProduct: {},
  fetchingDiscounts: false,
  fetchingDiscountsFail: false,
  discounts: {},
  cartDataRedux: {
    checkAll: false,
    stores: []
  },
  validateCartResult: null,
  isCartDataValidated: false
};

const reducer = createReducer(defaultState, {
  [REQUEST_CHECKING_INVENTORY]: (state, action) => ({
    ...state,
    resultCheckQuantityProduct: false,
  }),
  [REQUEST_CHECKING_INVENTORY_SUCCESS]: (state, action) => ({
    ...state,
    resultCheckQuantityProduct: get(action, 'payload.data'),
  }),
  [REQUEST_CHECKING_INVENTORY_FAILURE]: (state, action) => ({
    ...state,
    resultCheckQuantityProduct: false,
  }),
  [REQUEST_CREATE_CART]: (state, action) => ({
    ...state,
    error: {},
  }),
  [REQUEST_CREATE_CART_SUCCESS]: (state, action) => ({
    ...state,
    error: {},
  }),
  [REQUEST_CREATE_CART_FAILURE]: (state, action) => ({
    ...state,
    error: get(action, 'payload.data'),
  }),
  [REQUEST_VOUCHER_SUITABLE]: (state, action) => ({
    ...state,
    fetchingDiscountSuitable: true,
    fetchingDiscountSuitableFail: false,
  }),
  [REQUEST_VOUCHER_SUITABLE_SUCCESS]: (state, action) => ({
    ...state,
    fetchingDiscountSuitable: false,
    fetchingDiscountSuitableFail: false,
    discount: get(action, 'payload.data'),
  }),
  [REQUEST_VOUCHER_SUITABLE_FAILURE]: (state, action) => ({
    ...state,
    fetchingDiscountSuitable: false,
    fetchingDiscountSuitableFail: true,
  }),
  [REQUEST_VOUCHER_PRODUCT]: (state, action) => ({
    ...state,
    fetchingVoucherKpiProduct: true,
    fetchingVoucherKpiProductFail: false,
    voucherKpiProduct: {}
  }),
  [REQUEST_VOUCHER_PRODUCT_SUCCESS]: (state, action) => ({
    ...state,
    fetchingVoucherKpiProduct: false,
    fetchingVoucherKpiProductFail: false,
    voucherKpiProduct: get(action, 'payload.data'),
  }),
  [REQUEST_VOUCHER_PRODUCT_FAILURE]: (state, action) => ({
    ...state,
    fetchingVoucherKpiProduct: false,
    fetchingVoucherKpiProductFail: true,
    voucherKpiProduct: {},
  }),
  [REQUEST_DISCOUNTS]: (state, action) => ({
    ...state,
    fetchingDiscounts: true,
    fetchingDiscountsFail: false,
  }),
  [REQUEST_DISCOUNTS_SUCCESS]: (state, action) => ({
    ...state,
    fetchingDiscounts: false,
    fetchingDiscountsFail: false,
    discounts: get(action, 'payload.data'),
  }),
  [REQUEST_DISCOUNTS_FAILURE]: (state, action) => ({
    ...state,
    fetchingDiscounts: false,
    fetchingDiscountsFail: true,
  }),
  [REQUEST_VALIDATE_CART_SUCCESS]: (state, action) => ({
    ...state,
    validateCartResult: get(action, 'payload.data')
  }),
  [ACTION_UPDATE_CART]: (state, action) => ({
    ...state,
    cartDataRedux: get(action, 'payload'),
  }),
  [ACTION_VALIDATE_CART_DONE]: (state, action) => ({
    ...state,
    isCartDataValidated: get(action, 'payload'),
  })
});

export default reducer;
export const namespace = 'cart';

export const actionUpdateCart = (payload) => ({
  type: ACTION_UPDATE_CART,
  payload
});

export const actionValidateCartDone = (payload) => ({
  type: ACTION_VALIDATE_CART_DONE,
  payload
});

export const createOrder = (data)=> async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/seller/orders',
    actionTypes: [REQUEST_CREATE_CART, REQUEST_CREATE_CART_SUCCESS, REQUEST_CREATE_CART_FAILURE],
    variables: data,
    dispatch,
    getState
  });
};

export const checkingInventory = (data) => async (dispatch, getState) =>{
  return await callAPI({
    method: 'post',
    apiPath: '/seller/products/checking-quantity',
    actionTypes: [REQUEST_CHECKING_INVENTORY, REQUEST_CHECKING_INVENTORY_SUCCESS, REQUEST_CHECKING_INVENTORY_FAILURE],
    variables: data,
    dispatch,
    getState
  });
}

export const getDiscountsSuitable = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/seller/discounts/discount-suitable',
    actionTypes: [REQUEST_VOUCHER_SUITABLE, REQUEST_VOUCHER_SUITABLE_SUCCESS, REQUEST_VOUCHER_SUITABLE_FAILURE],
    variables: {...data},
    dispatch,
    getState
  });
}

export const getDiscountProductKpiSeller = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/seller/vouchers/voucher-product',
    actionTypes: [REQUEST_VOUCHER_PRODUCT, REQUEST_VOUCHER_PRODUCT_SUCCESS, REQUEST_VOUCHER_PRODUCT_FAILURE],
    variables: {...data},
    dispatch,
    getState
  });
}

export const getDiscounts = (param) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/seller/discounts',
    actionTypes: [REQUEST_DISCOUNTS, REQUEST_DISCOUNTS_SUCCESS, REQUEST_DISCOUNTS_FAILURE],
    variables: param,
    dispatch,
    getState
  });
}

export const validateCart = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/seller/cart/validate',
    actionTypes: [REQUEST_VALIDATE_CART, REQUEST_VALIDATE_CART_SUCCESS, REQUEST_VALIDATE_CART_FAILURE],
    variables: data,
    dispatch,
    getState
  });
}