import _ from 'lodash'
import moment from "moment";
import {
  HN_STORE,
  SG_STORE,
  SHIPPING_BASIC,
  SHIPPING_INNER_CITY, UNIT_VOUCHER_AMOUNT,
  UNIT_VOUCHER_PERCENT, VOUCHER_TYPE_AMOUNT,
  VOUCHER_TYPE_PERCENT
} from "./constants";

export const isEmptyAllValue = (obj) => {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] !== "")
      return false;
  }
  return true;
}

export const getErrorMessage = (errors, field) => {
  let result = ''
  let error = _.find(errors, { 'field': field })
  if (error !== undefined) {
    result = error.message
  }
  return result
}

export const hasError = (errors, field) => {
  return _.find(errors, { 'field': field }) !== undefined
}

export const arrayValueToString = (array) => {
  let result = ''

  _.forEach(array, (value, index) => {
    if ((index + 1) !== array.length) {
      result += `${value},`
    } else {
      result += value
    }
  })

  return result
}

export const formatDate = (date, format = 'DD/MM/YYYY') => {
  if (date) {
    return moment.unix(date).format(format)
  }
}

export const formatPrice = (price) => {
  if (price === undefined) {
    return '0đ';
  }
  return price.toLocaleString() + 'đ';
}

export const formatPhoneNumber = (phoneNumber) => {
  return '0' === phoneNumber[0] ? phoneNumber.replace(/0/, '+84') : phoneNumber;
}

export const filterProductChecked = () => {
  let cart = {
    checkAll: false,
    stores: []
  };
  const dataCart = localStorage.getItem('orders') ? JSON.parse(localStorage.getItem('orders')) : cart;
  let data = {...dataCart};

  let totalAll = 0;
  let totalPay = 0;
  let totalPaySupplier = 0;
  let totalCollaboratorsPrice = 0;
  let totalPriceDiscount = 0;
  let totalPayDisplay = 0;
  let hasDiscount = false;
  let totalPaySupplierWithSale = 0;
  // eslint-disable-next-line array-callback-return
  let stores = data.stores.map((store) => {
    store.message = '';

    let storeSupplierPrice = 0;
    if (store.product_gift && Object.keys(store.product_gift).length > 0 &&
        (store.product_gift.gift_price || store.product_gift.gift_price === 0)) {
      totalPriceDiscount += store.product_gift.gift_price;
      if (!(!store.product_gift.with_sales && '1' === process.env.REACT_APP_SHIPPING_FEE_WITH_SALES)) {
        storeSupplierPrice += store.product_gift.gift_price;
      }
      hasDiscount = true;
    }

    // eslint-disable-next-line array-callback-return
    let products = store.products.map(product => {
      if (product.checked) {
        totalPay += (parseFloat(product.collaborators_price) * parseFloat(product.quantity));
        const productSupplierPrice = (parseFloat(product.supplier_price) * parseFloat(product.quantity));
        if (product.with_sales) {
          totalPaySupplierWithSale += productSupplierPrice;
        }
        totalPaySupplier += productSupplierPrice;
        if (!(!product.with_sales && '1' === process.env.REACT_APP_SHIPPING_FEE_WITH_SALES)) {
          storeSupplierPrice += productSupplierPrice;
        }
        return product;
      } else {
        return false;
      }
    })

    products = products.filter(p => p !== false);

    if (products && products.length === 0) {
      return false;
    } else {
      store.products = products;
      // set store default transportation service
      store.transport = null;
      store.showSelectTransport = true;
      store.storeSupplierPrice = storeSupplierPrice;
      return store;
    }
  })

  stores = stores.filter(s => s !== false);
  totalAll = totalPay;
  totalPayDisplay = totalPay + totalPriceDiscount;
  totalCollaboratorsPrice = (totalPay - totalPaySupplier);
  data = {...data, stores, totalPay, totalAll, totalPaySupplier, totalCollaboratorsPrice, totalPriceDiscount,
    totalPayDisplay, hasDiscount, totalPaySupplierWithSale, payment: {method: 'cod', image: ''}, customer: {}};

  return data;
}

export const getTransportServiceNote = (transportationService, storeName = '') => {
  switch (transportationService.service_id) {
    case SHIPPING_INNER_CITY:
      if (HN_STORE === storeName) {
        return 'Nhận hàng nhanh hơn Giao hàng tiêu chuẩn. Áp dụng cho các quận nội thành trừ Long Biên, Tây Hồ.';
      } else if (SG_STORE === storeName) {
        return 'Nhận hàng nhanh hơn Giao hàng tiêu chuẩn. Áp dụng cho Quận 1, Quận 3, Quận 4, Quận 5, Quận 10, Quận 11, Quận Tân Phú, Quận Tân Bình, Quận Phú Nhuận, Quận Bình Thạnh.';
      } else {
        return '';
      }
    case SHIPPING_BASIC:
      return 'Nhận hàng sau 3 - 7 ngày';
    default:
      return '';
  }
}

export const getCurrentUrl = () => {
  const url = new URL(window.location.href);
  const param = new URLSearchParams(url.search);
  if (param && (param.has("utm_campaign") ||
    param.has("utm_content") ||
    param.has("utm_medium") ||
    param.has("utm_source"))
  ) {
    localStorage.setItem("tracking_utm", param.toString());
  }
}

export const formatFullname = (data) => {
  return isEmpty(data) ? '' : (!isEmpty(data.first_name) || !isEmpty(data.last_name) ? ((isEmpty(data.first_name) ? '' : data.first_name) + ' ' + (isEmpty(data.last_name) ? '' : data.last_name)) : isEmpty(data.username) ? data.email : data.username);
}

export const isEmpty = (variable) => {
  return undefined === variable || '' === variable || (Array.isArray(variable) && 0 === variable.length) || false === variable || null === variable || ('object' === typeof variable && 0 === Object.keys(variable).length);
}

export const unitUtil = (unit) => {
  let text = '';
  switch (unit) {
    case VOUCHER_TYPE_PERCENT:
      return  UNIT_VOUCHER_PERCENT
    case VOUCHER_TYPE_AMOUNT:
      return  UNIT_VOUCHER_AMOUNT;
    default:
      return text;
  }
}

export const resetCartData = (dataCart) => {
  // reset cartData to default value
  let productId = null;
  dataCart.stores.map(store => {
    store.product_gift = null;
    store.checked = false;
    const products = store.products.map((itemP) => {
      itemP.checked = false;

      if (itemP.isClone && itemP.isClone === "voucher_clone") {
        productId = itemP.id;
        return false;
      }

      itemP.checked_voucher = false;
      itemP.supplier_price = itemP.supplier_price_old;

      return itemP;
    });

    store.products = products.filter(p => p !== false);

    if (productId) {
      let productExist = store.products.find(({ id }) => id === productId);
      ++productExist.quantity;
    }

    return store;
  });

  dataCart.checkAll = false;
  dataCart.totalPayDisplay = 0;
  dataCart.voucher = null;

  return dataCart;
}

export const buildCartValidationInput = () => {
  let dataCart = localStorage.getItem('orders') ? JSON.parse(localStorage.getItem('orders')) : { checkAll: false, stores: [] };
  dataCart = resetCartData(dataCart);

  if (dataCart.stores) {
    return dataCart.stores.map(s => {
      return {
        store_id: s.id,
        products: s.products.map(p => {
          const product = _.pick(p, ['market_price', 'quantity']);
          product._id = p.id;
          product.supplier_price = p.supplier_price_old;

          return product;
        })
      }
    });
  } else {
    return [];
  }
}