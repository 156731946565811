import {
  all,
  put,
  fork,
  select,
  takeLatest
} from 'redux-saga/effects';

import {
  getAllOrders,
  ACTION_CHANGE_ORDER_FILTER,
  REQUEST_CANCEL_ORDER_SUCCESS,
  REQUEST_CANCEL_ORDER_FAILURE
} from './index'

import { showToastAlert } from "../app";

function* loadRouteData() {
  yield put(getAllOrders())
}

function* handleListenActions() {
  yield takeLatest([
    ACTION_CHANGE_ORDER_FILTER
  ], function* (action) {
    const { order } = yield select();
    const {
      filter
    } = order;
    yield put(getAllOrders(filter))
  });
}

function* handleDeleteOrderSuccess() {
  yield takeLatest(REQUEST_CANCEL_ORDER_SUCCESS, function* (action) {
    yield put(showToastAlert('Hủy đơn hàng thành công'))
    yield put(getAllOrders())
  });
}

function* handleDeleteOrderFailure() {
  yield takeLatest(REQUEST_CANCEL_ORDER_FAILURE, function* (action) {
    yield put(showToastAlert('Hủy đơn hàng thất bại', 'error'))
    yield put(getAllOrders())
  });
}

export function* loadOrderPage() {
  yield all([
    fork(handleListenActions),
    fork(loadRouteData),
    fork(handleDeleteOrderSuccess),
    fork(handleDeleteOrderFailure),
  ]);
}
