import {
  all,
  put,
  fork,
  takeLatest
} from 'redux-saga/effects';

import {
    REQUEST_CREATE_CART_SUCCESS,
    REQUEST_CREATE_CART_FAILURE,
    getDiscountProductKpiSeller,
    getDiscounts,
    validateCart
} from "./index"

import { showToastAlert } from "../app";
import {getVouchersOfUser} from "../voucher";
import { buildCartValidationInput } from "../../../utils/helper";

function* loadRouteData() {
    yield put(getDiscountProductKpiSeller());
    yield put(getVouchersOfUser());
    yield put(getDiscounts({totalPay: 0}));
    yield put(validateCart(buildCartValidationInput()));
}

function* handleCreateCartSuccess() {
    yield takeLatest(REQUEST_CREATE_CART_SUCCESS, function* (action) {
        yield put(showToastAlert('Tạo đơn hàng thành công'))
    });
}

function* handleCreateCartFailure() {
    yield takeLatest(REQUEST_CREATE_CART_FAILURE, function* (action) {
        yield put(showToastAlert('Tạo đơn hàng thất bại', 'error'))
    });
}

export function* loadCartPage() {
  yield all([
    fork(loadRouteData),
    fork(handleCreateCartSuccess),
    fork(handleCreateCartFailure),
  ]);
}
  