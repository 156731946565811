import {
    fork, all, put
} from 'redux-saga/effects';
import {getInvitations} from "../bonusInvitationList";
import {getAffiliate, getSlides} from "./index";

function* loadRouteData() {
    yield put(getInvitations())
    yield put(getSlides())
    yield put(getAffiliate())
}

function* handleListenActions() {
}

export function* loadAffiliatePage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions)
    ]);
}
