import {
    all,
    put,
    fork, takeLatest, select,
} from 'redux-saga/effects';

import {
    getCategories,
} from '../category'

import {
    ACTION_CHANGE_PRODUCT_FILTER,
    getProducts,
    getNewProducts,
    getBestSellProducts
} from "../product";

import {
    ACTION_CHANGE_TOP_CONTRIBUTORS_FILTER,
    getSlides,
    topContributors,
    getSugesstion, getTrainingSchedule
} from "./index"
import {getCategoryContent} from "../content";

function* loadRouteData() {
    yield put(getCategories({ page: 1, limit: 100, display_order: 'asc' }));
    yield put(getProducts())
    yield put(getNewProducts())
    yield put(getBestSellProducts())
    yield put(getSlides())
    yield put(getSugesstion())
    yield put(topContributors())
    yield put(getTrainingSchedule())
    yield put(getCategoryContent())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_PRODUCT_FILTER,
    ], function* (action) {
        const {product} = yield select();
        const {
            filter
        } = product;
        yield put(getProducts(filter))
    });
    yield takeLatest([
        ACTION_CHANGE_TOP_CONTRIBUTORS_FILTER,
    ], function* (action) {
        const {home} = yield select();
        const {
            filter
        } = home;
        yield put(topContributors(filter))
    });
}

export function* loadHomePage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions),
    ]);
}
