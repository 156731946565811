
// Toolbar constants
export const TYPE_FILTER_LIST = 'TYPE_FILTER_LIST'
export const TYPE_FILTER_INPUT = 'TYPE_FILTER_INPUT'
export const TYPE_FILTER_DATE = 'TYPE_FILTER_DATE'
export const CONDITION_LESS_THAN = 'lt'
export const CONDITION_EQUAL = 'eq'
export const CONDITION_GREATER_THAN = 'gt'
export const CONDITION_IS = 'is'
export const CONDITION_NOT_IS = 'is_not'
export const CONDITION_IN = 'range'
export const CONDITION_NOT_IN = 'out_of_range'
export const CONDITION_CONTAIN = 'like'
export const CONDITION_NOT_CONTAIN = 'not_like'
export const DATA_TYPE_INT = 'int'
export const DATA_TYPE_STRING = 'string'
export const DATA_TYPE_FLOAT = 'float'

// Campaigns constants
export const TYPE_LIVESTREAM = 'livestream'
export const TYPE_COMMENT = 'comment'
export const TYPE_NEWS_NEW = 'new'
export const TYPE_NEWS_OLD = 'old'

export const VN_CHARS = 'ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ'

// Rewards constants
export const TYPE_ROTATION_ITEM_LUCKY = 'LUCKY'
export const TYPE_ROTATION_ITEM_MORE = 'MORE'
export const TYPE_ROTATION_ITEM_GIFT = 'GIFT'

export const TYPE_ARTIFACT     = 1;
export const TYPE_ROTATION     = 2;
export const TYPE_DIAL_NUMBER  = 3;

export const DEFAULT_CONFIRM_LEAVE_MESSAGE = 'Nếu bạn rời khỏi trang này, tất cả những dữ liệu chưa được lưu sẽ bị mất đi. Bạn có chắc chắn muốn rời trang này không?'

export const VALIDATE_EMAIL_REGEX = /^[a-z][a-z0-9_.]{1,}@[a-z0-9]{1,}(\.[a-z0-9]{1,}){1,2}$/
export const VALIDATE_PHONE_REGEX = /((^0|^84|^\+84|^\(\+84\))+([0-9]{2}))+([0-9]{7}|[0-9]{1}\.[0-9]{3}\.[0-9]{3}|[0-9]{1}-[0-9]{3}-[0-9]{3}|[0-9]{1}\s[0-9]{3}\s[0-9]{3}$)/

// Role constants
export const ROLE_OWNER = 'owner';
export const ROLE_ADMIN = 'admin';
export const ROLE_EDITOR = 'editor';
export const ROLE_MARKETTER = 'marketer';
export const ROLE_CS = 'cs';

// Contract status
export const CONTRACT_STATUS_PENDING = 1;
export const CONTRACT_STATUS_CANCELED = 2;
export const CONTRACT_STATUS_PAID = 3;
export const CONTRACT_STATUS_EXPIRED = 4;

// Sex
export const SEXES = [
  {
    value: 'male',
    text: 'Nam'
  },
  {
    value: 'female',
    text: 'Nữ'
  },
  {
    value: 'other',
    text: 'Khác'
  }
];

// sort
export const SORT = [
  // {
  //   value: 'commission',
  //   text: 'Hoa hồng cao'
  // },
  {
    value: 'new',
    text: 'Mới nhất'
  },
  // {
  //   value: 'best_sale',
  //   text: 'Bán chạy nhất'
  // },
  {
    value: 'price_asc',
    text: 'Giá từ thấp tới cao'
  },
  {
    value: 'price_desc',
    text: 'Giá từ cao tới thấp'
  }
];

// Datetime
export const DATE_FORMAT = 'DD/MM/YYYY';

// transportation
export const SHIPPING_INNER_CITY = 'inner_city';
export const SHIPPING_BASIC = 'basic';

export const DISCOUNT_TYPE_ORDER = 'order';
//export const DISCOUNT_TYPE_PRODUCT = 'product';
//export const DISCOUNT_TYPES = [DISCOUNT_TYPE_ORDER, DISCOUNT_TYPE_PRODUCT];

export const HN_STORE = 'HN';
export const SG_STORE = 'SG';

export const VOUCHER_TYPE_PERCENT = 'percent';
export const VOUCHER_TYPE_AMOUNT = 'amount';

export const UNIT_VOUCHER_PERCENT = '%';
export const UNIT_VOUCHER_AMOUNT = 'VNĐ';

export const PRODUCT_VALIDATION_TYPE_PRODUCT_CHANGED = 'product_changed';
export const PRODUCT_VALIDATION_TYPE_ALMOST_OUT_OF_STOCK = 'almost_out_of_stock';
export const PRODUCT_VALIDATION_TYPE_OUT_OF_STOCK = 'out_of_stock';
export const PRODUCT_VALIDATION_TYPES = [PRODUCT_VALIDATION_TYPE_PRODUCT_CHANGED, PRODUCT_VALIDATION_TYPE_ALMOST_OUT_OF_STOCK, PRODUCT_VALIDATION_TYPE_OUT_OF_STOCK];

export const SALE_GROUP_JOIN_REQUEST_STATUS_PENDING = 'pending';
export const SALE_GROUP_JOIN_REQUEST_STATUS_APPROVED = 'approved';
export const SALE_GROUP_JOIN_REQUEST_STATUS_REJECTED = 'rejected';

export const SALE_GROUP_POSITION_LEADER = 'leader';
export const SALE_GROUP_POSITION_VICE_TEAM = 'vice_team';
export const SALE_GROUP_POSITION_MEMBER = 'member';

export const PAYMENT_METHOD_VNPAY = 'vnpay';