import {
    all,
    put,
    select,
    fork,takeLatest
} from 'redux-saga/effects';
import {
    ACTION_CHANGE_SALE_GROUP_FILTER,
    getJoinRequestsOfGroup,
    getMemberOfGroup, getPositionMember,
    getProgramSalesGroup,
    getRequestJoinSaleGroupOfUser,
    getSaleGroupJoined,
    getSalesGroup,
    REQUEST_ADD_SALES_GROUP_FAILURE,
    REQUEST_ADD_SALES_GROUP_SUCCESS, REQUEST_GET_OUT_GROUP_FAILURE, REQUEST_GET_OUT_GROUP_SUCCESS,
    REQUEST_JOIN_SALE_GROUP_FAILURE,
    REQUEST_JOIN_SALE_GROUP_SUCCESS, REQUEST_KICK_MEMBER_FAILURE, REQUEST_KICK_MEMBER_SUCCESS,
    REQUEST_UN_JOIN_SALE_GROUP_FAILURE,
    REQUEST_UN_JOIN_SALE_GROUP_SUCCESS, REQUEST_UPDATE_POSITION_MEMBER_FAILURE, REQUEST_UPDATE_POSITION_MEMBER_SUCCESS,
    REQUEST_UPDATE_STATUS_JOIN_REQUEST_FAILURE,
    REQUEST_UPDATE_STATUS_JOIN_REQUEST_SUCCESS
} from "./index";
import {showToastAlert} from "../app";

function* loadRouteData() {
    yield put(getProgramSalesGroup());
    yield put(getSalesGroup());
    yield put(getSaleGroupJoined());
    yield put(getRequestJoinSaleGroupOfUser());
    yield put(getPositionMember());
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_SALE_GROUP_FILTER
    ], function* (action) {
        const { saleGroup } = yield select();
        const {
            filter
        } = saleGroup;
        yield put(getSalesGroup(filter))
    });
}

function* handleAddSalesGroupSuccess() {
    yield takeLatest(REQUEST_ADD_SALES_GROUP_SUCCESS, function* (action) {
        yield put(showToastAlert('Tạo hội thành công!'));
        yield put(getProgramSalesGroup());
        yield put(getSalesGroup());
        yield put(getRequestJoinSaleGroupOfUser());
        yield put(getPositionMember());
    });
}

function* handleJoinSaleGroupSuccess() {
    yield takeLatest([
        REQUEST_JOIN_SALE_GROUP_SUCCESS,
        REQUEST_UN_JOIN_SALE_GROUP_SUCCESS], function* (action) {
        yield put(showToastAlert('Thao tác thành công!'));
        yield put(getSalesGroup());
        yield put(getRequestJoinSaleGroupOfUser());
        yield put(getPositionMember());
    });
}

function* handleOutGroupSuccess() {
    yield takeLatest([REQUEST_GET_OUT_GROUP_SUCCESS], function* (action) {
        yield put(showToastAlert('Thao tác thành công!'));
        window.location.reload();
    });
}

function* handleUpdateStatusJoinRequestSuccess() {
    yield takeLatest([
        REQUEST_UPDATE_STATUS_JOIN_REQUEST_SUCCESS,
        REQUEST_UPDATE_POSITION_MEMBER_SUCCESS,
        REQUEST_KICK_MEMBER_SUCCESS
    ], function* (action) {
        yield put(showToastAlert('Thao tác thành công!'));
        if (action && action.meta && action.meta.variables) {
            yield put(getMemberOfGroup(action.meta.variables.sale_group));
            yield put(getJoinRequestsOfGroup(action.meta.variables.sale_group));
            yield put(getPositionMember());
        }
    });
}

function* handleAddSalesGroupFailure() {
    yield takeLatest(REQUEST_ADD_SALES_GROUP_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.data.message, 'error'));
        yield put(getProgramSalesGroup());
        yield put(getSalesGroup());
        yield put(getRequestJoinSaleGroupOfUser());
        yield put(getPositionMember());
    });
}

function* handleJoinSaleGroupFailure() {
    yield takeLatest([
        REQUEST_JOIN_SALE_GROUP_FAILURE,
        REQUEST_UN_JOIN_SALE_GROUP_FAILURE,
        REQUEST_GET_OUT_GROUP_FAILURE], function* (action) {
        yield put(showToastAlert(action.payload.data.message, 'error'));
        yield put(getSalesGroup());
        yield put(getRequestJoinSaleGroupOfUser());
        yield put(getPositionMember());
    });
}

function* handleUpdateStatusJoinRequestFailure() {
    yield takeLatest([
        REQUEST_UPDATE_STATUS_JOIN_REQUEST_FAILURE,
        REQUEST_UPDATE_POSITION_MEMBER_FAILURE,
        REQUEST_KICK_MEMBER_FAILURE], function* (action) {
        yield put(showToastAlert(action.payload.data.message, 'error'));
        if (action && action.meta && action.meta.variables) {
            yield put(getMemberOfGroup(action.meta.variables.sale_group));
            yield put(getJoinRequestsOfGroup(action.meta.variables.sale_group));
            yield put(getPositionMember());
        }
    });
}

export function* loadSaleGroupPage() {
    yield all([
        fork(loadRouteData),
        fork(handleAddSalesGroupSuccess),
        fork(handleAddSalesGroupFailure),
        fork(handleJoinSaleGroupSuccess),
        fork(handleJoinSaleGroupFailure),
        fork(handleListenActions),
        fork(handleUpdateStatusJoinRequestSuccess),
        fork(handleUpdateStatusJoinRequestFailure),
        fork(handleOutGroupSuccess),
    ]);
}
