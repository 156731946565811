import {
    put,
    fork, all, select,
} from 'redux-saga/effects';

import {
    getDocsBySlug,
} from './index'

function* loadRouteData() {
    const {location} = yield select();
    if (location.payload.id) {
        yield put(getDocsBySlug(location.payload.id));
    }
}

export function* loadDocsPage() {
    yield all([
        fork(loadRouteData)
    ]);
}
