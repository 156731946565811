import { createReducer } from 'redux-create-reducer';
import callAPI from "../../../utils/callAPI";
import {get} from "lodash";

export const REQUEST_BLOGS         = 'REQUEST_BLOGS';
export const REQUEST_BLOGS_SUCCESS = 'REQUEST_BLOGS_SUCCESS';
export const REQUEST_BLOGS_FAILURE = 'REQUEST_BLOGS_FAILURE';
export const ACTION_CHANGE_BLOGS_FILTER = 'ACTION_CHANGE_BLOGS_FILTER';


const defaultState = {
    fetchingBlogs: false,
    failedFetchingBlogs: false,
    blogs: {
        results: []
    },
    filter: {
        search_key: "",
        page: 1,
    },
};
const reducer = createReducer(defaultState, {
    [REQUEST_BLOGS_SUCCESS]: (state, action) => ({
        ...state,
        blogs: get(action, 'payload.data'),
        fetchingBlogs: false,
        failedFetchingBlogs: false,
    }),

    [REQUEST_BLOGS]: (state, action) => ({
        ...state,
        fetchingBlogs: true,
        failedFetchingBlogs: false,
    }),

    [REQUEST_BLOGS_FAILURE]: (state, action) => ({
        ...state,
        fetchingBlogs: false,
        failedFetchingBlogs: true,
    }),

    [ACTION_CHANGE_BLOGS_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload')
    }),

});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_BLOGS_FILTER,
    payload
});

export const getBlogBySlug = (slug, params = { page: 1, limit: 12, sortBy:'updated_at:desc' }) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/page_contents/blogs/'+slug,
        actionTypes: [REQUEST_BLOGS, REQUEST_BLOGS_SUCCESS, REQUEST_BLOGS_FAILURE],
        variables: { ...params },
        dispatch,
        getState
    });
};

export default reducer;
export const namespace = 'blogs';
