import { createReducer } from 'redux-create-reducer';
import callAPI from "../../../utils/callAPI";
import {get} from "lodash";

export const REQUEST_SLIDES         = 'REQUEST_SLIDES';
export const REQUEST_SLIDES_SUCCESS = 'REQUEST_SLIDES_SUCCESS';
export const REQUEST_SLIDES_FAILURE = 'REQUEST_SLIDES_FAILURE';

export const REQUEST_TOP_CONTRIBUTORS         = 'REQUEST_TOP_CONTRIBUTORS';
export const REQUEST_TOP_CONTRIBUTORS_SUCCESS = 'REQUEST_TOP_CONTRIBUTORS_SUCCESS';
export const REQUEST_TOP_CONTRIBUTORS_FAILURE = 'REQUEST_TOP_CONTRIBUTORS_FAILURE';

export const REQUEST_REVENUE         = 'REQUEST_REVENUE';
export const REQUEST_REVENUE_SUCCESS = 'REQUEST_REVENUE_SUCCESS';
export const REQUEST_REVENUE_FAILURE = 'REQUEST_REVENUE_FAILURE';

export const REQUEST_TRAINING_SCHEDULE         = 'REQUEST_TRAINING_SCHEDULE';
export const REQUEST_TRAINING_SCHEDULE_SUCCESS = 'REQUEST_TRAINING_SCHEDULE_SUCCESS';
export const REQUEST_TRAINING_SCHEDULE_FAILURE = 'REQUEST_TRAINING_SCHEDULE_FAILURE';

export const ACTION_CHANGE_TOP_CONTRIBUTORS_FILTER = 'ACTION_CHANGE_TOP_CONTRIBUTORS_FILTER';

export const REQUEST_SUGGESSTION    = 'REQUEST_SUGGESSTION';
export const REQUEST_SUGGESSTION_SUCCESS = 'REQUEST_SUGGESSTION_SUCCESS';
export const REQUEST_SUGGESSTION_FAILURE = 'REQUEST_SUGGESSTION_FAILURE';

const defaultState = {
    fetchingSlides: false,
    failedFetchingSlides: false,
    fetchingSugesstion: false,
    failedfetchingSugesstion: false,
    fetchingTopContributors: false,
    failedFetchingTopContributors: false,
    fetchingRevenueUser: false,
    failedFetchingRevenueUser: false,
    fetchingTrainingSchedule: false,
    failedFetchingTrainingSchedule: false,
    slides: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    suggestions: {
        total: 0,
        suggest: []
    },
    topContributors: [],
    revenueUser: [],
    trainingSchedule: [],
    errors: []
};
const reducer = createReducer(defaultState, {
    [REQUEST_SLIDES_SUCCESS]: (state, action) => ({
        ...state,
        slides: get(action, 'payload.data'),
        fetchingSlides: false,
        failedFetchingSlides: false
    }),

    [REQUEST_SLIDES]: (state, action) => ({
        ...state,
        fetchingSlides: true,
        failedFetchingSlides: false,
    }),

    [REQUEST_SLIDES_FAILURE]: (state, action) => ({
        ...state,
        fetchingSlides: false,
        failedFetchingSlides: true,
    }),

    [REQUEST_TOP_CONTRIBUTORS]: (state, action) => ({
        ...state,
        fetchingTopContributors: true,
        failedFetchingTopContributors: false,
    }),

    [REQUEST_TOP_CONTRIBUTORS_SUCCESS]: (state, action) => ({
        ...state,
        topContributors: get(action, 'payload.data'),
        fetchingTopContributors: false,
        failedFetchingTopContributors: false
    }),

    [REQUEST_TOP_CONTRIBUTORS_FAILURE]: (state, action) => ({
        ...state,
        fetchingTopContributors: false,
        failedFetchingTopContributors: true,
    }),

    [REQUEST_REVENUE]: (state, action) => ({
        ...state,
        fetchingRevenueUser: true,
        failedFetchingRevenueUser: false,
    }),

    [REQUEST_REVENUE_SUCCESS]: (state, action) => ({
        ...state,
        revenueUser: get(action, 'payload.data'),
        fetchingRevenueUser: false,
        failedFetchingRevenueUser: false,
    }),

    [REQUEST_REVENUE_FAILURE]: (state, action) => ({
        ...state,
        fetchingRevenueUser: false,
        failedFetchingRevenueUser: true,
    }),
    
    [REQUEST_TRAINING_SCHEDULE]: (state, action) => ({
        ...state,
        fetchingTrainingSchedule: true,
        failedFetchingTrainingSchedule: false,
    }),

    [REQUEST_TRAINING_SCHEDULE_SUCCESS]: (state, action) => ({
        ...state,
        trainingSchedule: get(action, 'payload.data'),
        fetchingTrainingSchedule: false,
        failedFetchingTrainingSchedule: false,
    }),

    [REQUEST_TRAINING_SCHEDULE_FAILURE]: (state, action) => ({
        ...state,
        fetchingTrainingSchedule: false,
        failedFetchingTrainingSchedule: true,
    }),

    [ACTION_CHANGE_TOP_CONTRIBUTORS_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),


    [REQUEST_SUGGESSTION_SUCCESS]: (state, action) => ({
        ...state,
        suggestions: get(action, 'payload.data'),
        failedfetchingSugesstion: false,
        failedFetchingSlides: false
    }),

    [REQUEST_SUGGESSTION]: (state, action) => ({
        ...state,
        fetchingSugesstion: true,
        failedfetchingSugesstion: false,
    }),

    [REQUEST_SUGGESSTION_FAILURE]: (state, action) => ({
        ...state,
        fetchingSugesstion: false,
        failedfetchingSugesstion: true,
    })
});

export default reducer;
export const namespace = 'home';

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_TOP_CONTRIBUTORS_FILTER,
    payload
});

export const getSlides = (params = {page: 1, sortBy:'index:asc'}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/slide',
        actionTypes: [REQUEST_SLIDES, REQUEST_SLIDES_SUCCESS, REQUEST_SLIDES_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const getSugesstion = (query) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/suggestion',
        actionTypes: [REQUEST_SUGGESSTION, REQUEST_SUGGESSTION_SUCCESS, REQUEST_SUGGESSTION_FAILURE],
        variables: query,
        dispatch,
        getState
    });
};

export const topContributors = (params = {limit:6}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/orders/top-contributors',
        actionTypes: [REQUEST_TOP_CONTRIBUTORS, REQUEST_TOP_CONTRIBUTORS_SUCCESS, REQUEST_TOP_CONTRIBUTORS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

//getRevenueByUser
export const getRevenueByUser = (params = {limit:6}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/orders/top-contributors',
        actionTypes: [REQUEST_REVENUE, REQUEST_REVENUE_SUCCESS, REQUEST_REVENUE_FAILURE], variables: {...params},
        dispatch,
        getState
    });
};

export const getTrainingSchedule = (params = {limit:5}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/training-schedule',
        actionTypes: [REQUEST_TRAINING_SCHEDULE, REQUEST_TRAINING_SCHEDULE_SUCCESS, REQUEST_TRAINING_SCHEDULE_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};