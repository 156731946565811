import { createReducer } from 'redux-create-reducer';
import callAPI from "../../../utils/callAPI";
import {get} from "lodash";

export const REQUEST_BONUS_INVITATIONS         = 'REQUEST_BONUS_INVITATIONS';
export const REQUEST_BONUS_INVITATIONS_SUCCESS = 'REQUEST_BONUS_INVITATIONS_SUCCESS';
export const REQUEST_BONUS_INVITATIONS_FAILURE = 'REQUEST_BONUS_INVITATIONS_FAILURE';
export const ACTION_CHANGE_BONUS_INVITATIONS_FILTER = 'ACTION_CHANGE_BONUS_INVITATIONS_FILTER';


const defaultState = {
    fetchingBonusInvitations: false,
    failedFetchingBonusInvitations: false,
    bonusInvitations: {
        results: []
    },
    filter: {
        search_key: "",
        page: 1,
    },
};
const reducer = createReducer(defaultState, {
    [REQUEST_BONUS_INVITATIONS_SUCCESS]: (state, action) => ({
        ...state,
        bonusInvitations: get(action, 'payload.data'),
        fetchingBonusInvitations: false,
        failedFetchingBonusInvitations: false,
    }),

    [REQUEST_BONUS_INVITATIONS]: (state, action) => ({
        ...state,
        fetchingBonusInvitations: true,
        failedFetchingBonusInvitations: false,
    }),

    [REQUEST_BONUS_INVITATIONS_FAILURE]: (state, action) => ({
        ...state,
        fetchingBonusInvitations: false,
        failedFetchingBonusInvitations: true,
    }),

    [ACTION_CHANGE_BONUS_INVITATIONS_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload')
    }),

});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_BONUS_INVITATIONS_FILTER,
    payload
});

export const getInvitations = (params = { page: 1, limit: 12}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/bonus/invitations',
        actionTypes: [REQUEST_BONUS_INVITATIONS, REQUEST_BONUS_INVITATIONS_SUCCESS, REQUEST_BONUS_INVITATIONS_FAILURE],
        variables: { ...params },
        dispatch,
        getState
    });
};

export default reducer;
export const namespace = 'bonusInvitations';
