import {
    put,
    fork, select, all,takeLatest
} from 'redux-saga/effects';

import {
    getBlogBySlug,
    ACTION_CHANGE_BLOGS_FILTER
} from './index'
import {getCategoryContent} from "../content";

function* loadRouteData() {
    const {location} = yield select();
    yield put(getCategoryContent())
    if (location.payload.slug) {
        yield put(getBlogBySlug(location.payload.slug));
    }
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_BLOGS_FILTER
    ], function* (action) {
        const { blogs, location } = yield select();
        const {
            filter
        } = blogs;
        yield put(getBlogBySlug(location.payload.slug, filter));
    });
}

export function* loadBlogsPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions)
    ]);
}
