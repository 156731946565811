import { createReducer } from 'redux-create-reducer';
import callAPI from "../../../utils/callAPI";
import {get} from "lodash";

export const REQUEST_CATEGORIES_CONTENT    = 'REQUEST_CATEGORIES_CONTENT';
export const REQUEST_CATEGORIES_CONTENT_SUCCESS = 'REQUEST_CATEGORIES_CONTENT_SUCCESS';
export const REQUEST_CATEGORIES_CONTENT_FAILURE = 'REQUEST_CATEGORIES_CONTENT_FAILURE';

export const REQUEST_CONTENTS = 'REQUEST_CONTENTS';
export const REQUEST_CONTENTS_SUCCESS = 'REQUEST_CONTENTS_SUCCESS';
export const REQUEST_CONTENTS_FAILURE = 'REQUEST_CONTENTS_FAILURE';

export const REQUEST_CONTENT_DETAIL = 'REQUEST_CONTENT_DETAIL';
export const REQUEST_CONTENT_DETAIL_SUCCESS = 'REQUEST_CONTENT_DETAIL_SUCCESS';
export const REQUEST_CONTENT_DETAIL_FAILURE = 'REQUEST_CONTENT_DETAIL_FAILURE';

const defaultState = {
    fetchingContents: false,
    failedFetchingContents: false,
    fetchingCategoriesContent: false,
    failedFetchingCategoriesContent: false,
    contents: {
        results: []
    },
    content: {
        results: []
    },
    categoriesContent: {
        results: []
    }
};
const reducer = createReducer(defaultState, {
    [REQUEST_CATEGORIES_CONTENT_SUCCESS]: (state, action) => ({
        ...state,
        categoriesContent: get(action, 'payload.data'),
        fetchingCategoriesContent: false,
        failedFetchingCategoriesContent: false,
    }),

    [REQUEST_CATEGORIES_CONTENT]: (state, action) => ({
        ...state,
        fetchingCategoriesContent: true,
        failedFetchingCategoriesContent: false,
    }),

    [REQUEST_CATEGORIES_CONTENT_FAILURE]: (state, action) => ({
        ...state,
        fetchingCategoriesContent: false,
        failedFetchingCategoriesContent: true,
    }),

    [REQUEST_CONTENTS_SUCCESS]: (state, action) => ({
        ...state,
        contents: get(action, 'payload.data'),
        fetchingContents: false,
        failedFetchingContents: false
    }),
    [REQUEST_CONTENT_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        content: get(action, 'payload.data'),
        fetchingContents: false,
        failedFetchingContents: false
    })
});

export const getContents = (params = {page: 1, sortBy:'updated_at:desc'}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/page_contents',
        actionTypes: [REQUEST_CONTENTS, REQUEST_CONTENTS_SUCCESS, REQUEST_CONTENTS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const getContentById = (contentId) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/page_contents/'+contentId,
        actionTypes: [REQUEST_CONTENT_DETAIL, REQUEST_CONTENT_DETAIL_SUCCESS, REQUEST_CONTENT_DETAIL_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const getCategoryContent = (params = {page: 1}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/page_contents/docs-categories',
        actionTypes: [REQUEST_CATEGORIES_CONTENT, REQUEST_CATEGORIES_CONTENT_SUCCESS, REQUEST_CATEGORIES_CONTENT_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export default reducer;
export const namespace = 'content';
