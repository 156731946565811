import { fork, all } from 'redux-saga/effects';
import routeSaga from '../modules/routing/sagas.js';
import appSaga from '../modules/app/sagas.js';
import authSaga from '../modules/auth/sagas.js';
import checkPageAccess from '../modules/checkPageAccess/sagas.js';
import notificationSaga from '../modules/notification/saga';
import * as localStorage from 'utils/localStorage';

export default function* sagas() {
  const sagasList = [
    fork(routeSaga),
    fork(appSaga),
    fork(authSaga),
    fork(checkPageAccess)
  ];
  if (localStorage.getAuthToken()) {
    sagasList.push(fork(notificationSaga));
  }
  yield all(sagasList);
}
