import {
  all,
  put,
  fork,
  select,
  takeLatest
} from 'redux-saga/effects';

import {
  getOrderById,
} from './index'
import {REQUEST_CANCEL_ORDER_FAILURE, REQUEST_CANCEL_ORDER_SUCCESS} from "../order";
import {showToastAlert} from "../app";

function* loadRouteData() {
  const {location} = yield select();
  if (location.payload.id) {
    yield put(getOrderById(location.payload.id));
  }
}

function* handleDeleteOrderDetailSuccess() {
  yield takeLatest(REQUEST_CANCEL_ORDER_SUCCESS, function* (action) {
    yield put(showToastAlert('Hủy đơn hàng thành công'))
    const {location} = yield select();
    if (location.payload.id) {
      yield put(getOrderById(location.payload.id));
    }
  });
}

function* handleDeleteOrderDetailFailure() {
  yield takeLatest(REQUEST_CANCEL_ORDER_FAILURE, function* (action) {
    yield put(showToastAlert('Hủy đơn hàng thất bại, không thể hủy đơn hàng đang được xử lý!', 'error'))
    const {location} = yield select();
    if (location.payload.id) {
      yield put(getOrderById(location.payload.id));
    }
  });
}

export function* loadOrderDetailPage() {
  yield all([
    fork(loadRouteData),
    fork(handleDeleteOrderDetailSuccess),
    fork(handleDeleteOrderDetailFailure),
  ]);
}
