import { createReducer } from 'redux-create-reducer';
import {get} from "lodash";
import callAPI from "../../../utils/callAPI";

export const REQUEST_AFFILIATE         = 'REQUEST_AFFILIATE';
export const REQUEST_AFFILIATE_SUCCESS = 'REQUEST_AFFILIATE_SUCCESS';
export const REQUEST_AFFILIATE_FAILURE = 'REQUEST_AFFILIATE_FAILURE';
export const ACTION_CHANGE_AFFILIATE_FILTER = 'ACTION_CHANGE_AFFILIATE_FILTER';

export const REQUEST_SLIDES_BONUS_         = 'REQUEST_SLIDES_BONUS_';
export const REQUEST_SLIDES_BONUS__SUCCESS = 'REQUEST_SLIDES_BONUS__SUCCESS';
export const REQUEST_SLIDES_BONUS__FAILURE = 'REQUEST_SLIDES_BONUS__FAILURE';

const defaultState = {
    fetchingAffiliate: false,
    failedFetchingAffiliate: false,
    affiliate: {
        results: []
    },
    filter: {
        search_key: "",
        page: 1,
    },
    slides: {
        results: []
    },
};
const reducer = createReducer(defaultState, {
    [REQUEST_AFFILIATE_SUCCESS]: (state, action) => ({
        ...state,
        affiliate: get(action, 'payload.data'),
        fetchingAffiliate: false,
        failedFetchingAffiliate: false,
    }),

    [REQUEST_AFFILIATE]: (state, action) => ({
        ...state,
        fetchingAffiliate: true,
        failedFetchingAffiliate: false,
    }),

    [REQUEST_AFFILIATE_FAILURE]: (state, action) => ({
        ...state,
        fetchingAffiliate: false,
        failedFetchingAffiliate: true,
    }),

    [ACTION_CHANGE_AFFILIATE_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload')
    }),

    [REQUEST_SLIDES_BONUS__SUCCESS]: (state, action) => ({
        ...state,
        slides: get(action, 'payload.data'),
        fetchingSlides: false,
        failedFetchingSlides: false
    }),

    [REQUEST_SLIDES_BONUS_]: (state, action) => ({
        ...state,
        fetchingSlides: true,
        failedFetchingSlides: false,
    }),

    [REQUEST_SLIDES_BONUS__FAILURE]: (state, action) => ({
        ...state,
        fetchingSlides: false,
        failedFetchingSlides: true,
    }),

});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_AFFILIATE_FILTER,
    payload
});

export const getAffiliate = (params) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/bonus/affiliate',
        actionTypes: [REQUEST_AFFILIATE, REQUEST_AFFILIATE_SUCCESS, REQUEST_AFFILIATE_FAILURE],
        variables: { ...params },
        dispatch,
        getState
    });
};

export const getSlides = (params = {page: 1, search_key:'bonus'}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/slide',
        actionTypes: [REQUEST_SLIDES_BONUS_, REQUEST_SLIDES_BONUS__SUCCESS, REQUEST_SLIDES_BONUS__FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export default reducer;
export const namespace = 'affiliate';
