import {
  all,
  fork,
  put,
  takeLatest,
  select
} from 'redux-saga/effects';
import { redirect } from 'redux-first-router';
import { get } from 'lodash';
import { showToastAlert } from "../app";
import {
  // FETCH_USER_SUCCESS,
  REQUEST_LOGOUT_SUCCESS,
  FETCH_USER_FAILURED,
  REQUEST_LOGIN_SUCCESS,
  REQUEST_LOGIN_FAILURED,
  REQUEST_REGISTER_SUCCESS,
  REQUEST_REGISTER_FAILURED,
  REQUEST_FORGOT_PASSWORD_SUCCESS,
  REQUEST_FORGOT_PASSWORD_FAILURED,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_FAILURED,
  REQUEST_VALIDATE_BEFORE_REGISTER_SUCCESSED,
  REQUEST_VALIDATE_BEFORE_REGISTER_FAILURED,
  logout as logoutAction,
  saveResetPasswordToken
} from '.';

import {
  ROUTE_LOGIN,
  ROUTE_HOME,
  ROUTE_REGISTER,
  ROUTE_RESET_PASSWORD,
  ROUTE_WELCOME,
  selectRouteType,
  selectRoutesMap
} from '../routing';
import {
  fetchUserInfor,
} from '../auth/index'
import * as localStorage from 'utils/localStorage';

function* loadRouteData() {
  const routeType = yield select(selectRouteType);

  if (routeType === ROUTE_RESET_PASSWORD) {
    const {location} = yield select();
    yield put(saveResetPasswordToken(location.payload.token));
  }

  if (!localStorage.getAuthToken()) {
    const routesMap = yield select(selectRoutesMap);
    const currentRoute = routesMap[routeType];
    if (currentRoute.requiresAuth) {
      yield put(redirect({
        type: ROUTE_WELCOME,
        query: ROUTE_WELCOME
      }));
    } else {
      const { location } = yield select();
      yield put(redirect({
        type: routeType,
        payload: location.payload,
        query: location.query
      }));
    }
  }
  else if (routeType === ROUTE_LOGIN) {
    yield put(redirect({
      type: ROUTE_HOME,
      query: ROUTE_HOME
    }));
  }
}

function* redirectAwayAfterUserLogin() {
  yield takeLatest(REQUEST_LOGIN_SUCCESS, function*(action) {
    localStorage.setAuthToken(action.payload.data.access_token);
    const { location } = yield select();
    const redirectType = get(location, 'query.redirect', '');
    const payload = get(location, 'payload', '');
    yield put(fetchUserInfor());
    yield put(redirect({
      type: redirectType && redirectType !== ROUTE_LOGIN ? redirectType : ROUTE_HOME,
      payload
    }));
  });
}

function* redirectAwayAfterUserRegister() {
  yield takeLatest(REQUEST_REGISTER_SUCCESS, function*(action) {
    yield put(showToastAlert(action.payload.message))
    window.localStorage.removeItem('tracking_utm');
    const { location } = yield select();
    const redirectType = get(location, 'query.redirect', '');
    const payload = get(location, 'payload', '');
    yield put(redirect({
      type: redirectType && redirectType !== ROUTE_REGISTER ? redirectType : ROUTE_LOGIN,
      payload
    }));
  });
}

function* showMessageAfterUserForgotPassword() {
  yield takeLatest(REQUEST_FORGOT_PASSWORD_SUCCESS, function*(action) {
    yield put(showToastAlert(action.payload.message))
  });
}

function* redirectAwayAfterUserResetPassword() {
  yield takeLatest(REQUEST_RESET_PASSWORD_SUCCESS, function*(action) {
    yield put(showToastAlert(action.payload.message))
    const { location } = yield select();
    const redirectType = get(location, 'query.redirect', '');
    const payload = get(location, 'payload', '');
    yield put(redirect({
      type: redirectType && redirectType !== ROUTE_RESET_PASSWORD ? redirectType : ROUTE_LOGIN,
      payload
    }));
  });
}

function* logoutHandle() {
    yield takeLatest(REQUEST_LOGOUT_SUCCESS, function*(action) {
      const { location } = yield select();
      localStorage.removeAuthToken();
      window.localStorage.removeItem('orders');
      if (location.type !== ROUTE_LOGIN) {
        yield put(redirect({
          type: ROUTE_WELCOME
        }));
      }
      
    });

    yield takeLatest(FETCH_USER_FAILURED, function*(action) {
      yield put(logoutAction);
    });
}

function* handleLoginFailure() {
    yield takeLatest(REQUEST_LOGIN_FAILURED, function* (action) {
    yield put(showToastAlert('Tài khoản hoặc mật khẩu không chính xác', 'error'))
  });
}

function* handleRegisterFailure() {
  yield takeLatest(REQUEST_REGISTER_FAILURED, function* (action) {
    yield put(showToastAlert('Có lỗi xảy ra', 'error'))
  });
}

function* handleForgotPasswordFailure() {
  yield takeLatest(REQUEST_FORGOT_PASSWORD_FAILURED, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  });
}

function* handleResetPasswordFailure() {
  yield takeLatest(REQUEST_RESET_PASSWORD_FAILURED, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  });
}

function* handleValidateBeforeRegisterSuccessed() {
  yield takeLatest(REQUEST_VALIDATE_BEFORE_REGISTER_SUCCESSED, function* (action) {
    if (!action.payload.data.status) {
      yield put(showToastAlert(action.payload.data.message, 'error'))
    }
  });
}

function* handleValidateBeforeRegisterFailured() {
  yield takeLatest(REQUEST_VALIDATE_BEFORE_REGISTER_FAILURED, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  });
}

export default function* auth() {
  yield all([
    fork(loadRouteData),
    fork(logoutHandle),
    fork(handleLoginFailure),
    fork(redirectAwayAfterUserLogin),
    fork(redirectAwayAfterUserRegister),
    fork(handleRegisterFailure),
    fork(showMessageAfterUserForgotPassword),
    fork(handleForgotPasswordFailure),
    fork(redirectAwayAfterUserResetPassword),
    fork(handleResetPasswordFailure),
    fork(handleValidateBeforeRegisterSuccessed),
    fork(handleValidateBeforeRegisterFailured)
  ]);
}
