import { createReducer } from 'redux-create-reducer';
import callAPI from "../../../utils/callAPI";
import {get} from "lodash";

export const REQUEST_CONTENT_APP         = 'REQUEST_CONTENT_APP';
export const REQUEST_CONTENT_APP_SUCCESS = 'REQUEST_CONTENT_APP_SUCCESS';
export const REQUEST_CONTENT_APP_FAILURE = 'REQUEST_CONTENT_APP_FAILURE';

const defaultState = {
    fetchingContentApp: false,
    failedFetchingContentApp: false,
    contentApp: {
        title: "",
        content: ""
    },
};
const reducer = createReducer(defaultState, {
    [REQUEST_CONTENT_APP_SUCCESS]: (state, action) => ({
        ...state,
        contentApp: get(action, 'payload.data'),
        fetchingContentApp: false,
        failedFetchingContentApp: false,
    }),

    [REQUEST_CONTENT_APP]: (state, action) => ({
        ...state,
        fetchingContentApp: true,
        failedFetchingContentApp: false,
    }),

    [REQUEST_CONTENT_APP_FAILURE]: (state, action) => ({
        ...state,
        fetchingContentApp: false,
        failedFetchingContentApp: true,
    }),

});

export const getBlogBySlug = (slug) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/seller/page_contents/'+slug,
        actionTypes: [REQUEST_CONTENT_APP, REQUEST_CONTENT_APP_SUCCESS, REQUEST_CONTENT_APP_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export default reducer;
export const namespace = 'contentApp';
